import * as Landing from './pages/landing';
import * as Details from './pages/details';
import * as AtoZ from './pages/atoz';
import "moment/locale/en-gb";
import "moment/locale/cy";
import moment from "moment";
import { loadResources } from "../src/Util/resource";
import telemetry from "./telemetry/application-insights";
import $ from "jquery";
// @ts-ignore
window.$ = window.jQuery = $;

moment.locale(document.documentElement.lang === "en" ? "en-gb" : "cy");

loadResources();

export { Landing, Details, AtoZ };
