﻿import { deviceListener, device } from "../Util/deviceListener";

const tabs = {

    viewContainer: null,
    tabs: null,
    selectedTab: null,
    tabActiveCssClass: "tab--active",
    viewContainerClass: "view-container",
    state: null,

    tabTypeToCssModifier: {
        mapView: "map-view",
        combinedView: "combined-view",
        listView: "list-view",
    },

    init: function(options) {
        this.state = options.state;
        this.tabs = document.querySelectorAll(".tabs__tab");
        this.viewContainer = document.querySelector("." + this.viewContainerClass);

        this.tabs.forEach((tab) => {
            tab.addEventListener("click", this.onTabClick.bind(this));
        });

        this.setDisplay();
    },

    onTabClick: function(ev) {
        var tab = ev.currentTarget;
        this.selectTab(tab);

        var tabType = tab.dataset.view;
        if (this.state) this.state.addTab(tabType);
        this.triggerEvent('tabSelected', tabType);
    },

    selectTabType: function(tabType) {
        let tabIndex = -1;

        if (tabType === 'mapView') {
            tabIndex = 0;
        } else if (tabType === 'listView') {
            tabIndex = 2;
        }

        this.selectTab(this.tabs[tabIndex]);
        this.triggerEvent('tabSelected', tabType);
    },

    selectTab: function(tab) {
        this.selectedTab = tab;

        var tabType = tab.dataset.view;

        var classes = this.viewContainer.classList;

        for (var i = 0; i < classes.length; i++) {
            var cssClass = classes[i];

            if (cssClass === this.viewContainerClass) {
                continue;
            }

            this.viewContainer.classList.remove(cssClass);
        }

        for (var i = 0; i < this.tabs.length; i++) {
            var currentTab = this.tabs[i];
            // remove active from all tabs
            currentTab.classList.remove(this.tabActiveCssClass);

            if (currentTab === tab) {
                // add active to new tab
                currentTab.classList.add(this.tabActiveCssClass);
                continue;
            }
        }

        var newCssClass = this.viewContainerClass + "--" + this.tabTypeToCssModifier[tabType];
        this.viewContainer.classList.add(newCssClass);
    },

    setDisplay: function () {
        if (deviceListener.display === device.Mobile || deviceListener.display === device.Tablet) {
            this.selectTab(this.tabs[0]);
        } else {
            this.selectTab(this.tabs[1]);
        }

        deviceListener.mobileListener.addListener(this.setLastSelectedTab.bind(this));
        deviceListener.tabletListener.addListener(this.setLastSelectedTab.bind(this));
        deviceListener.desktopListener.addListener((mediaQueryList) => {
            if (mediaQueryList.matches) {
                this.selectTab(this.tabs[1]);
                this.state.addTab();
            }
        });
    },

    setLastSelectedTab: function (mediaQueryList) {
        if (!mediaQueryList.matches) {
            return;
        }

        if (this.selectedTab) {
            this.selectTab(this.selectedTab);
        } else {
            this.selectTab(this.tabs[0]);
        }
    },

    eventListeners: [],

    addEventListener: function (eventName, callback) {
        this.eventListeners.push({
            eventName: eventName,
            callback: callback
        });
    },

    triggerEvent: function (eventName, data) {
        this.eventListeners.filter(x => x.eventName === eventName).forEach(event => {
            if (!event.callback) return;
            event.callback(data);
        });
    }
};

export default tabs;
