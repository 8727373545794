import regex from '../../constants/regex';
import mapUtils from '../../Util/mapUtils';
import telemetry from '../../telemetry/application-insights';
import responseCodes from '../../constants/responseCodes';

const ngrSearch = {
    doSearch: function (keyword, map) {
        let gridRef = null;
        const searchCriteria = keyword.replace(/\s/g, '');
        const ngrMatch = searchCriteria.match(RegExp(regex.search.ngr));
        const eastingNorthingMatch = searchCriteria.match(RegExp(regex.search.eastingNorthing));

        if (eastingNorthingMatch) {
            gridRef = { easting: Number(eastingNorthingMatch[1]), northing: Number(eastingNorthingMatch[2]) }
            return this._searchByGridReference(gridRef, map);
        } else if (ngrMatch) {
            gridRef = mapUtils.convertNgrToEastingNorthing(searchCriteria);
            return this._searchByGridReference(gridRef, map);
        }
    },

    _searchByGridReference: function(gridRef, map) {
        return new Promise((resolve, reject) => {
            if (!gridRef || gridRef.error) {
                resolve(this._parseResults(false));
            } else {
                const latlng = map.convertToLatLongPoint(gridRef.easting, gridRef.northing);
                
                const successCallback = (result) => {
                    resolve(this._parseResults(parseInt(result), latlng));
                };

                const failureCallback = () => {
                    let errorMessage = 'Failed to get national grid reference';
                    if (appInsights.logError) appInsights.logError(null, errorMessage)
                    reject(errorMessage);
                }

                mapUtils.isWithinWales(latlng.lat, latlng.lng, successCallback, failureCallback);
            }
        });
    },

    _parseResults: function (result, latlng) {
        let results = [];
        let ok = false;

        switch (result)
        {
            case responseCodes.reverseLookupResult.withinWales:
                ok = true;
                break;
            case responseCodes.reverseLookupResult.unknown:
                // this is returned when the results on the  server side weren't able to parse properly. This usually
                // occurs due to the location appearing to be in the sea. Fallback onto the welsh extent rectangle                
                ok = mapUtils.isWithinWalesExtent(latlng);
                break;
        }

        if (ok) {
            results.push({
                point: latlng,
                selected: this.listItemSelected.bind(latlng)
            });
        }
        
        return {
            id: 'ngr',
            results: results,
        };
    },

    listItemSelected: function (map, callback) {
        if (callback) callback();

        map.centreOnPoint({ 
            point: {
                latitude: this.lat,
                longitude: this.lng,
                radius: 10000
            }
        });
    }
}

export default ngrSearch;
