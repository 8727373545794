﻿import attributes from '../constants/attributes';
import map from '../components/map';
import mapUtils from '../Util/mapUtils';
import telemetry from '../telemetry/application-insights';

const thirdPartyIntegration = (function () {

    function init(queryString) {

        if (!queryString) {
            return;
        }

        const urlParams = new URLSearchParams(queryString);

        const ngr = urlParams.get('ngr');
        if (ngr) {
            document.querySelector(attributes.css.class.searchInput).value = ngr;
            document.querySelector(attributes.css.id.searchButton).click();
            return;
        }

        const extent = urlParams.get('extent');
        if (extent) {

            const coords = extent.split(' ');
            const swX = parseFloat(coords[0]);
            const swY = parseFloat(coords[1]);
            const neX = parseFloat(coords[2]);
            const neY = parseFloat(coords[3]);
            const mapExtent = map.convertToLatLongExtent(swX, swY, neX, neY);

            //Wales extent is a bit strict, pad it a little so coastal places like North of Angelsey are accepted
            if (!mapUtils.isWithinWalesExtent(mapExtent)) {
                if (telemetry.logError) telemetry.logError(new Error(`Third Party Integration: - extent is not within Wales: ${extent}`));
                return;
            }

            map.fitBounds(mapExtent);

            return;
        }
    }

    return {
        init: init
    }
})();

export default thirdPartyIntegration;
