﻿const constants = (function () {

    function getIcon (imageUrl) {
        if (!L || !L.Point) return imageUrl;

        return new L.Icon({
            iconUrl: imageUrl,
            iconSize: new L.Point(36, 36)
        });
    }

    return {
        severityIconRenderer: {
            0: {
                // TODO: Required however Severity 0 icons are not display on map
                symbol: getIcon("/images/floodwarnings/flood-alerts-icon.png"),
                clusterIconUrl: "/images/floodwarnings/cluster-alert.png"
            },
            1: {
                symbol: getIcon("/images/floodwarnings/severe-flood-warnings-icon.png"),
                clusterIconUrl: "/images/floodwarnings/cluster-severe.png"
            },
            2: {
                symbol: getIcon("/images/floodwarnings/flood-warnings-icon.png"), 
                clusterIconUrl: "/images/floodwarnings/cluster-severe.png"
            },
            3: {
                symbol: getIcon("/images/floodwarnings/flood-alerts-icon.png"),
                clusterIconUrl: "/images/floodwarnings/cluster-alert.png"
            },
            4: {
                // TODO: Required however Severity 4 icons are not display on map
                symbol: getIcon("/images/floodwarnings/flood-alerts-icon.png"),
                clusterIconUrl: "/images/floodwarnings/cluster-alert.png"
            },
        },

        dottedLineStyle: {
            weight: 4,
            dashArray: '5, 10',
            lineCap: 'square', // Optional, just to avoid round borders.
            color: 'black'
        },

        polygonStyleBySeverity: {
            "0": {
                fill: [0, 0, 0, 0],
                outline: [0, 0, 0, 1],
                outlineWidth: 0,
                highlightedOutlineWidth: 3,
                highlightedOutlineColour: [0, 0, 0, 1],
                detailsPageOutline: [0, 0, 0, 1],
                detailsPageFill: [128, 128, 128, 0.5],
                detailsPageOutlineWidth: 2
            },
            "1": {
                fill: [255, 0, 0, 0.5],
                outline: [255, 0, 0, 1],
                outlineWidth: 2,
                highlightedOutlineWidth: 3,
                highlightedOutlineColour: [0, 0, 0, 1],
                detailsPageOutline: [0, 0, 0, 1],
                detailsPageFill: [255, 0, 0, 0.5],
                detailsPageOutlineWidth: 2
            },
            "2": {
                fill: [255, 0, 0, 0.5],
                outline: [255, 0, 0, 1],
                outlineWidth: 2,
                highlightedOutlineWidth: 3,
                highlightedOutlineColour: [0, 0, 0, 1],
                detailsPageOutline: [0, 0, 0, 1],
                detailsPageFill: [255, 0, 0, 0.5],
                detailsPageOutlineWidth: 2
            },
            "3": {
                fill: [255, 165, 0, 0.5],
                outline: [255, 165, 0, 1],
                outlineWidth: 2,
                highlightedOutlineWidth: 3,
                highlightedOutlineColour: [0, 0, 0, 1],
                detailsPageOutline: [0, 0, 0, 1],
                detailsPageFill: [255, 165, 0, 0.5],
                detailsPageOutlineWidth: 2
            },
            "4": {
                fill: [0, 0, 0, 0],
                outline: [0, 0, 0, 1],
                outlineWidth: 2,
                highlightedOutlineWidth: 3,
                highlightedOutlineColour: [0, 0, 0, 1],
                detailsPageOutline: [0, 0, 0, 1],
                detailsPageFill: [128, 128, 128, 0.5],
                detailsPageOutlineWidth: 2
            }
        },
        moment: {
            dateFormat: "HH:mm D MMM YYYY"
        },
        popup: {
            width: 320,
            className: "leaflet-customPopup",
            hoverTimeOut: 600,
            bounds: {
                x: 200,
                y: 240
            },
            mobile: { // used to detect when view is being viewed on a small screen size
                width: 620
            },
            desktop: {
                width: 900
            }
        },
        map: {
            zoom: {
                min: 8,
                delta: 1, // set how far the map will zoom in and out 
                snap: 0.25 // force map zoom to closes .25 level
            }
        },
        container: {
            detailsId: "detailsDiv",
            mainId : "viewDiv"
        },
        geometryTypes: {
            simple: 0,
            complex: 1
        },
        symbol: {
            selectedCssClass: 'symbol-icon-selected'
        },
        WalesExtent: new L.LatLngBounds(new L.LatLng(51.3, -5.4), new L.LatLng(53.5, -2.3))
    }
})();

export default constants;
