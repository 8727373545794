﻿import constants from "./../constants/mapConstants";
import attributes from "../constants/attributes";
import templates from "../constants/templates";
import CustomPopup from "../Extensions/Popup";

export default class CustomMarker extends L.Marker {

    severity = null;
    isHighlighted = false;
    simplePolygon = null;
    complexPolygon = null;

    bindPopup (htmlContent, options) {

        if (options && options.showOnMouseOver) {

            // call the super method
            L.Marker.prototype.bindPopup.apply(this, [htmlContent, options]);

            // unbind the click event
            this.off(attributes.events.click, this.openPopup, this);

            // bind to mouse over
            this.on(attributes.events.mouseover, function (e) {

                // get the element that the mouse hovered onto
                var target = e.originalEvent.fromElement || e.originalEvent.relatedTarget;
                var parent = this._getParent(target, constants.popup.className);

                // check to see if the element is a popup, and if it is this marker's popup
                if (parent === this._popup._container) {
                    return true;
                }

                // show the popup
                this.openPopup();

            }, this);

            // and mouse out
            this.on(attributes.events.mouseout, function (e) {
                this.mouseOutEvent(e);
            }, this);
        }
    }

    _popupMouseOut(e) {

        // detach the event
        L.DomEvent.off(this._popup, attributes.events.mouseout, this._popupMouseOut, this);

        // get the element that the mouse hovered onto
        var target = e.toElement || e.relatedTarget;

        // check to see if the element is a popup
        if (this._getParent(target, constants.popup.className)) {
            this.select(false);
            return true;
        }

        // check to see if the marker was hovered back onto
        if (target === this._icon) {
            return true;
        }

        this.unselect();

        // hide the popup
        setTimeout(() => {
            this.closePopup();
        },
        constants.popup.hoverTimeOut);
    }

    _getParent (element, className) {
        if (element === null || element.parentNode === null) {
            return false;
        }

        var parent = element.parentNode;

        while (parent != null) {
            if (parent.className && L.DomUtil.hasClass(parent, className)) {
                return parent;
            }
            parent = parent.parentNode;
        }

        return false;
    }

    hasPopup(event) {

        var target = event.originalEvent.romElement || event.originalEvent.relatedTarget;
        var parent = this._getParent(target, constants.popup.className);

        if (this._popup) {
            if (parent == this._popup._container) return true;
        }

        return false;
    }
    
    mouseOutEvent(e) {
        // get the element that the mouse hovered onto
        var target = e.originalEvent.toElement || e.originalEvent.relatedTarget;

        // check to see if the element is a popup
        if (this._getParent(target, constants.popup.className)) {
            if (this._popup) {
                L.DomEvent.on(this._popup._container, attributes.events.mouseout, this._popupMouseOut, this);
                return true;
            }
        }

        // hide the popup
        setTimeout(() => {
            this.closePopup();
            this.unselect();
        },
        constants.popup.hoverTimeOut);
    }

    setPolygonWeight(highlighted) {

        if (this.simplePolygon === null && this.complexPolygon === null) return;

        this.isHighlighted = highlighted;

        const severity = this.severity.toString();
        const style = constants.polygonStyleBySeverity[severity];
        if (!style) return;

        // Get the appropriate style values
        let weight;
        let color;
        if (highlighted) {
            weight = style.highlightedOutlineWidth;
            color = `rgba(${style.highlightedOutlineColour.join(',')})`;
        } else {
            weight = style.outlineWidth;
            color = `rgba(${style.outline.join(',')})`;
        }

        let opacity = 1;
        if (this.severity === 4) {
            opacity = highlighted ? 1 : 0;
        }

        if (this.simplePolygon !== null) {
            this.simplePolygon.setStyle({ weight, color, opacity });
        }

        if (this.complexPolygon !== null) {
            this.complexPolygon.setStyle({ weight, color, opacity });
        }
    }

    setPolygon(complexMode, polygon) {
        if (complexMode) {
            this.complexPolygon = polygon;
        }
        else {
            this.simplePolygon = polygon;
        }

        if (this.isHighlighted) {
            this.setPolygonWeight(this.isHighlighted);    
        }
    }

    showPopup(map, liveMessage, latlng) {

        var template = templates.getMapPopupTemplate(liveMessage);

        var popup = new CustomPopup({ offset: new L.Point(0, -10), autoPan: false, maxWidth: constants.popup.width })
            .setContent(template)
            .setLatLng(latlng)
            .openOn(map);

        popup.on(attributes.events.remove, () => {
            this.unselect();
        });

        this.bindPopup(popup);
    }

    select(highlightIcon) {

        if (highlightIcon && this._icon) {
            this._icon.classList.add(constants.symbol.selectedCssClass);
        }

        this.setPolygonWeight(true);
    }

    unselect() {

        if (!this.isHighlighted) {
            return;
        }

        if (this._map) {
            this._map.closePopup();
        }

        if (this._icon) {
            this._icon.classList.remove(constants.symbol.selectedCssClass);
        }
        
        this.setPolygonWeight(false);
    }

    hide() {
        this.setOpacity(0);
        this.options.interactive = false;
    }
}
