﻿import { Resource } from "../Util/resource";
import { keyCodes } from "../constants/keyCodes";
import attributes from "../constants/attributes";

const pagination = {
    paginationPageNumberContainer: document.querySelector(".pagination__page-number-container"),
    paginationFirst: document.querySelector(".pagination__page-link--first"),
    paginationPrevious: document.querySelector(".pagination__page-link--previous"),
    paginationNext: document.querySelector(".pagination__page-link--next"),
    paginationLast: document.querySelector(".pagination__page-link--last"),
    paginationItemsPerPage: document.querySelector("#paginationItemsPerPage"),
    allPaginationLinks: document.querySelectorAll(".pagination__page-link"),

    totalPageItems: 8,

    currentPage: 0,

    list: null,

    init: function (list) {
        this.list = list;

        this.paginationFirst.addEventListener(attributes.events.click, event => {
            if (this.currentPage > 0) this.navigateToPage(0, event);
        });
        this.paginationPrevious.addEventListener(attributes.events.click, event => {
            if (this.currentPage > 0) this.navigateToPage(this.currentPage - 1, event);
        });
        this.paginationNext.addEventListener(attributes.events.click, event => {
            if (this.currentPage < this.getTotalPages() - 1) this.navigateToPage(this.currentPage + 1, event);
        });
        this.paginationLast.addEventListener(attributes.events.click, event => {
            if (this.currentPage < this.getTotalPages() - 1) this.navigateToPage(this.getTotalPages() - 1, event);
        });

        this.allPaginationLinks.forEach(el => {
            el.addEventListener(attributes.events.keydown, e => {
                this.keydownHandler(e, el);
            })
        });

        const itemsPerPageOptions = [8, 16, 32];
        itemsPerPageOptions.forEach(number => {
            let option = document.createElement("option");
            option.text = number;
            option.value = number;
            this.paginationItemsPerPage.add(option);
        });

        this.paginationItemsPerPage.addEventListener("change", event => {
            this.totalPageItems = parseInt(this.paginationItemsPerPage.options[this.paginationItemsPerPage.selectedIndex].value);
            this.list.populateList(this.list.fullList, this.list.usePartial);
        });
    },

    keydownHandler: function(e, el) {
        const keycode = e.which || e.keycode;

        if (keycode === keyCodes.spacebar || keycode === keyCodes.return) {
            e.preventDefault();
            el.click();
        }
    },

    paginate: function () {
        if (!this.list.fullList) return;

        this.paginationPageNumberContainer.innerHTML = "";

        this.navigateToPage(0);
    },

    pageItemClicked: function (e) {
        const pageNumber = e.target.dataset.page;
        this.navigateToPage(pageNumber, e);

        e.preventDefault();
    },

    addPageItem: function (pageNumber, obscure) {
        let active = "pagination__page-link--active";
        if (pageNumber - 1 !== this.currentPage) active = "";

        const pageLabel = Resource.PageNumber.replace("{0}", pageNumber);

        const $template = $(`
            <a data-page="${pageNumber - 1}" class="pagination__page-link ${active}" style="float: left;" role="link" tabindex="0" aria-label="${pageLabel}" ${active ? "aria-current='true'" : ''}>
                <span data-page="${pageNumber - 1}" class="pagination__page-number">${obscure ? '...' : pageNumber}</span>
            </a>`);
        $template.on(attributes.events.click, this.pageItemClicked.bind(this));
        $template.on(attributes.events.keydown, e => this.keydownHandler(e, $template));
        $(this.paginationPageNumberContainer).append($template);
    },

    getTotalPages: function () {
        return Math.ceil(this.list.fullList.length / this.totalPageItems);
    },

    buildPaginationPageNumbers: function () {
        this.paginationPageNumberContainer.innerHTML = "";

        const buffer = 2;
        const maxPages = 6;
        const totalPages = this.getTotalPages();
        let startPage = this.currentPage - buffer;
        let endPage = this.currentPage + buffer < maxPages ? maxPages : this.currentPage + buffer + 1;

        if (startPage <= 0) startPage = 1;

        if (startPage > 1) this.addPageItem(startPage - 1, true);

        if (endPage > totalPages) endPage = totalPages;

        for (let i = startPage; i <= endPage; i++) {
            this.addPageItem(i, false);
        }
        if (endPage < totalPages) {
            this.addPageItem(endPage + 1, true);
        }

        this.updatePageLinkState();
    },

    navigateToPage: function (pageNumber, e) {
        this.list.listContainer.innerHTML = "";

        this.currentPage = parseInt(pageNumber);

        const start = this.currentPage * this.totalPageItems;
        const end = start + this.totalPageItems;

        const listItems = this.list.fullList.slice(start, end);
        this.list.addListItems(listItems);

        this.buildPaginationPageNumbers();
        this.list.setPaginationResultText(start + 1, end, this.list.fullList.length);

        if (!e) return;

        //from a button click, basically list should only scroll to top if it has come from a button click
        e.preventDefault();
        this.list.scrollToTop();
    },

    updatePageLinkState: function () {
        const disabledPaginationClass = "pagination__page-link--disabled";

        const firstPage = 0;
        const lastPage = this.getTotalPages() - 1;

        // Handle first/previous button state
        if (this.currentPage <= firstPage) {
            this.paginationFirst.classList.add(disabledPaginationClass);
            this.paginationPrevious.classList.add(disabledPaginationClass);
        } else {
            this.paginationFirst.classList.remove(disabledPaginationClass);
            this.paginationPrevious.classList.remove(disabledPaginationClass);
        }

        // Handle next/last button state
        if (this.currentPage >= lastPage) {
            this.paginationNext.classList.add(disabledPaginationClass);
            this.paginationLast.classList.add(disabledPaginationClass);
        } else {
            this.paginationNext.classList.remove(disabledPaginationClass);
            this.paginationLast.classList.remove(disabledPaginationClass);
        }
    }
};

export default pagination;
