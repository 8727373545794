import search from './search';
import searchResults from './searchResults';
import map from './map';
import tab from './tab';
import { allAuthorities } from './searchProviders/localAuthorities';
import { deviceListener, device } from "../Util/deviceListener";
import searchTypes from '../constants/searchTypes';

const state = (function() {
    var _resource;
    var _map;
    var _ignoredParams = ['mode'];
    var _params = {
    }
    const searchTermKey = 'term';
    const centreKey = 'centre';
    const authorityKey = 'authority';
    const selectedKey = 'selected'; // represents that a user selected a search result so no searching should be performed. We should have a point to centre on if they did before
    const tabStateKey = 'tab';
    const searchType = 'search-type';

    function init(options) {
        _resource = options.resource;
        _map = options.map;
    }

    function restoreState() {
        // see if we have any state parameters in the url
        var qs = window.location.href.split('?');
        let hasParameters = false;

        if (qs.length === 2) {
            
            const params = new URLSearchParams(qs[1]);

            for (var key of params.keys()) {

                if (_ignoredParams.indexOf(key) >= 0) {
                    continue
                }
                
                _params[key] = params.get(key);
                hasParameters = true;
            }

            if (hasParameters) {
                // set the params as part of the map, including extent, set a search, and culture
                // if there's a search, set those details first, and let the search complete
                // then set the extent
                if (_params[searchTermKey]) {
                    search.populateTerm(_params[searchTermKey]);

                    if (!_params[selectedKey]) {
                        // perform a search using the search term
                        search.search(_params[searchTermKey]);
                    } else {
                        // need to display the clear results message
                        searchResults.displayResult({
                            actionText: _resource.SearchResultsActionMessage,
                            actionLink: _resource.SearchResultsActionLink.replace('{0}', _map.getLiveMessageCount())
                        });
                    }
                }

                if (_params[authorityKey]) {
                    let obj = decode(authorityKey);

                    if (obj) {   
                        const authority = allAuthorities[obj.authorityId];

                        if (authority) {
                            authority.getData(map, obj.objectId, this);
                        }
                    }
                } else if (_params[centreKey]) {
                    // authority searches have higher importance than centre parameters
                    const centrePoint = decode(centreKey);

                    if (centrePoint) {
                        map.centreOnPoint({ centreAndZoom: centrePoint });
                    }
                }

                if (_params[tabStateKey]) {
                    if (deviceListener.display !== device.Desktop) {
                        tab.selectTabType(_params[tabStateKey]);
                    } else {
                        addTab();
                    }
                }

                if (_params[searchType] && _params[searchType] === searchTypes.location.toString()) {
                    search.searchCurrentLocation();
                }
            }
        }
    }

    function addSearchTerm(term, selected) {
        _params[searchTermKey] = term;

        if (selected) {
            _params[selectedKey] = 'y';
        } else {
            _params[selectedKey] = null;
        }
        recordState();
    }

    function addCentre(latLng, zoom) {
        if (latLng && zoom) {
            latLng = latLng.wrap();
            _params[centreKey] = encode({
                c: [ latLng.lat, latLng.lng ],
                z: zoom
            });
        } else {
            _params[centreKey] = null;
        }

        recordState();
    }

    function addTab(tabState) {
        if (tabState) {
            _params[tabStateKey] = tabState;
        } else {
            _params[tabStateKey] = null;
        }

        recordState();
    }

    function addAuthority(objectId, authorityId) {
        _params[authorityKey] = encode({
            objectId: objectId,
            authorityId: authorityId
        });
        
        recordState();
    }

    function addSearchType(type) {
        if (type) {
            _params[searchType] = type;
        } else {
            _params[searchType] = null;
        }

        recordState();
    }

    function clear() {
        for(var key in _params) {
            _params[key] = null;
        }
        recordState();
    }

    function encode(obj) {
        return btoa(JSON.stringify(obj));
    }

    function decode(key) {
        let obj = null;
        try {
            obj = JSON.parse(atob(_params[key]));
        } catch {
            _params[key] = null;
            recordState();
        }

        return obj;
    }

    function recordState() {
        let url;
        // either record in local storage, or querystring parameters - going to go with querystring so we can bookmark the site
        for(var key in _params) {
            url = updateQueryString(key, _params[key], url);
        }

        if (url) {
            history.pushState({}, null, url);
        }
    }

    function updateQueryString(key, value, url) {
        if (!url) url = window.location.href;
        var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
            hash;
    
        if (re.test(url)) {
            if (typeof value !== 'undefined' && value !== null) {
                return url.replace(re, '$1' + key + "=" + value + '$2$3');
            } 
            else {
                hash = url.split('#');
                url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '');
                if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                    url += '#' + hash[1];
                }
                return url;
            }
        }
        else {
            if (typeof value !== 'undefined' && value !== null) {
                var separator = url.indexOf('?') !== -1 ? '&' : '?';
                hash = url.split('#');
                url = hash[0] + separator + key + '=' + value;
                if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
                    url += '#' + hash[1];
                }
                return url;
            }
            else {
                return url;
            }
        }
    }

    return {
        init: init,
        restoreState: restoreState,
        addSearchTerm: addSearchTerm,
        addCentre: addCentre,
        addAuthority: addAuthority,
        addTab: addTab,
        addSearchType: addSearchType,
        clear: clear
    }
})();

export default state;
