﻿import telemetry from '../../telemetry/application-insights';

const townsAndPlaces = {

    _resource: null,

    doSearch: function (keyword, resource) {
        this._resource = resource;

        const searchQuery = `Country_EN = 'Wales' AND (LOWER(Search_EN) LIKE '${keyword}%' ESCAPE '$' OR LOWER(Search_CY) LIKE '${keyword}%' ESCAPE '$')`;
        const orderByFields = `((XMAX - XMIN) * (YMAX - YMIN)) DESC`;
        const whereClause = `where=${encodeURIComponent(searchQuery)}`;
        const uri = `/maplayerproxy/Places/FeatureServer/0/query?f=json&resultRecordCount=4&${whereClause}&orderByFields=${encodeURIComponent(orderByFields)}&outFields=Display_EN,Display_CY,County_EN,County_CY,Country_EN,Country_CY,XMIN,XMAX,YMIN,YMAX&returnGeometry=false&spatialRel=esriSpatialRelIntersects`;

        return new Promise((resolve, reject) => {
            $.get(uri, data => {
                let jsonData = '';
                try {
                    jsonData = JSON.parse(data);
                } catch (e) {
                    if (typeof data !== "object") {
                        telemetry.logError(e, `${e.name} - ${e.message}`);
                        reject(e.message);
                        return;
                    }
                    jsonData = data;
                }

                resolve(this._parseResults(jsonData));
            }).fail(() => {
                let errorMessage = 'Failed to get towns and places';
                if (telemetry.logError) telemetry.logError(null, errorMessage)
                reject(errorMessage);
            });
        });
    },

    _parseResults: function (searchResults) {
        let results = [];
        searchResults.features.forEach(feature => {
            const attributes = feature.attributes;

            const countyEN = attributes.County_EN.trim() !== attributes.Display_EN ? `, ${attributes.County_EN.trim()}` : '';
            const countyCY = attributes.County_CY.trim() !== attributes.Display_CY ? `, ${attributes.County_CY.trim()}` : '';

            const extent = {
                xmax: attributes.XMAX,
                xmin: attributes.XMIN,
                ymax: attributes.YMAX,
                ymin: attributes.YMIN,
            };

            if (!attributes.Display_CY) attributes.Display_CY = attributes.Display_EN;

            results.push({
                text: {
                    english: `${attributes.Display_EN}${countyEN}`,
                    welsh: `${attributes.Display_CY}${countyCY}`,
                },
                extent: extent,
                selected: this.listItemSelected.bind(extent)
            });
        });

        return {
            id: 'townsPlaces',
            title: this._resource.SearchCategoryPlaces,
            results: results
        };
    },

    listItemSelected: function (map, callback) {
        if (callback) callback();

        const extent = map.convertToLatLongExtent(this.xmin, this.ymin, this.xmax, this.ymax);
        map.centreOnPoint({extent: extent });
    }

};

export default townsAndPlaces;
