import 'leaflet';
import map from '../components/map'; 
import tabs from '../components/tab';
import list from '../components/list'; 
import pagination from '../components/pagination'; 
import dataStorage from '../persistence/data-storage'; 
import summary from '../components/summary'; 
import notification from '../components/notification'; 
import accordion from '../components/accordion';
import furtherInformation from '../components/furtherInformation';
import { deviceListener } from "../Util/deviceListener";
import search from '../components/search';
import state from '../components/state';
import { Resource } from "../Util/resource";
import refresh from '../components/refresh';
import thirdPartyIntegration from '../components/thirdPartyIntegration';
import constants from '../constants/mapConstants';

const Page = {
    options: {
        disableClustering: false,
        liveSummaryUpdates: true,
        enablePolygonHighlighting: true
    },

    build: function () {
        var walesExtent = constants.WalesExtent
        
        map.init({
            extent: walesExtent,
            liveMessages: dataStorage.getLiveMessages(),
            fwaCodes: dataStorage.getFwaaCodes(),
            basemapUrl: dataStorage.getBaseMapUrl(),
            clusteringDisabled: this.options.disableClustering,
            mapContainerId: null,
            disablePopups: false,
            enablePolygonHighlighting: this.options.enablePolygonHighlighting,
            showOutOfForceWarnings: false,
            state: state
        });

        list.init(pagination, map);
        summary.init();

        map.addEventListener(map.eventKeys.warningsFoundinExtent, data => {
            list.populateList(data.symbolData);

            if(!this.options.liveSummaryUpdates) return;
            summary.calculateSummaries(data.symbolData);
        });

        state.init({
            resource: Resource,
            map: map
        });
        deviceListener.init();
        tabs.init({
            state: state
        });

        map.addEventListener(map.eventKeys.mapUnavailable, () => {
            list.setListOnlyMode();
            notification.showError();
        });

        map.addEventListener(map.eventKeys.mapLoaded, () => {
            thirdPartyIntegration.init(window.location.search);
        });

        tabs.addEventListener('tabSelected', tabType => {
            // this causes the map to repaint itself based on the size of its container... it will automatically do this if the page resizes
            // but we need to do it manually its container resizes outside of a page resize event, i.e. a tab change
            // listView has no map, so allows it to use the users current view
            if (tabType === "combinedView" || tabType === "mapView") { 
                map.invalidate();
            }

            if (tabType === "combinedView" || tabType === "listView") {
                map.getWarningsInViewAndRaiseEvent();
                return;
            }
        });

        accordion('.accordion-area');

        furtherInformation.init();

        map.addEventListener(map.eventKeys.searchInvalidated, () => {
            search.clearSearch({
                searchCriteria: true,
                results: true,
                searchLayer: true
            })
        });

        search.init({
            extent: map.mapExtent,
            map: map,
            resource: Resource,
            state: state
        });

        this.addDeviceListners();
        refresh.init((updateRequired) => {
            if (updateRequired) {
                location.reload();
            }
        });
    },

    setData: function(baseMapUrl, liveMessages, fwaaCodes) {
        dataStorage.setBaseMapUrl(baseMapUrl);
        dataStorage.setLiveMessages(liveMessages);
        dataStorage.setFwaaCodes(fwaaCodes);
    },

    addDeviceListners: function () {
        deviceListener.mobileListener.addListener(invalidateMapSize);
        deviceListener.tabletListener.addListener(invalidateMapSize);
        deviceListener.desktopListener.addListener(invalidateMapSize);

        function invalidateMapSize() {
            // Bug fix for Edge when resizing from mobile to desktop view
            if (map) {
                map.invalidate();
            }
        }
    }
};

export { Page };
