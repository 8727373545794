﻿import constants from "../constants/mapConstants";

export default class CustomPopup extends L.Popup {

    _initLayout () {
        var prefix = constants.popup.className,
            container = this._container = L.DomUtil.create('div', `${prefix} ${this.options.className} leaflet-zoom-animated`),
            closeButton,
            closeButtonCss = '',
            wrapper;

        // Set the pixel distances from the map edges at which popups are too close and need to be re-oriented.
        var yBound = constants.popup.bounds.y,
            xBound = constants.popup.bounds.x;

        var pos = this._map.latLngToContainerPoint(this._latlng);
        var mapSize = this._map.getSize();

        // Determine the alternate direction to pop up; north mimics Leaflet's default behavior, so we initialize to that.
        this.options.position = 'n';

        // Then see if the point is too far north...
        var yDiff = yBound - pos.y;
        if (yDiff > 0) {
            this.options.position = 's';
        }

        // or too far east...
        var xDiff = pos.x - (mapSize.x - xBound);
        if (xDiff > 0) {
            this.options.position += 'w';
        } else {
            // or too far west.
            xDiff = xBound - pos.x;
            if (xDiff > 0) {
                this.options.position += 'e';
            }
        }

        if (mapSize.x < constants.popup.mobile.width) {
            this.options.position = 's';
            this._isMobile = true;
        }

        // Create the necessary DOM elements in the correct order. Pure 'n' and 's' conditions need only one class for styling, others need two.
        if (/s/.test(this.options.position)) {
            if (this.options.position === 's') {
                this._tipContainer = L.DomUtil.create('div', `${prefix}-tip-container`, container);
                wrapper = this._wrapper = L.DomUtil.create('div', `${prefix}-content-wrapper`, container);
            }
            else {
                this._tipContainer = L.DomUtil.create('div', `${prefix}-tip-container ${prefix}-tip-container-${this.options.position}`, container);
                wrapper = this._wrapper = L.DomUtil.create('div', `${prefix}-content-wrapper ${prefix}-content-wrapper-${this.options.position}`, container);
            }
            this._tip = L.DomUtil.create('div', `${prefix}-tip ${prefix}-tip-${this.options.position}`, this._tipContainer);
            L.DomEvent.disableClickPropagation(wrapper);
            this._contentNode = L.DomUtil.create('div', `${prefix}-content`, wrapper);
            L.DomEvent.on(this._contentNode, 'mousewheel', L.DomEvent.stopPropagation);
            closeButtonCss = ` ${prefix}-close-button-${this.options.position}`;
        }
        else {
            if (this.options.position === 'n') {
                wrapper = this._wrapper = L.DomUtil.create('div', `${prefix}-content-wrapper`, container);
                this._tipContainer = L.DomUtil.create('div', `${prefix}-tip-container`, container);
            } else {
                wrapper = this._wrapper = L.DomUtil.create('div', `${prefix}-content-wrapper ${prefix}-content-wrapper-${this.options.position}`, container);
                this._tipContainer = L.DomUtil.create('div', `${prefix}-tip-container ${prefix}-tip-container-${this.options.position}`, container);
            }
            L.DomEvent.disableClickPropagation(wrapper);
            this._contentNode = L.DomUtil.create('div', `${prefix}-content`, wrapper);
            L.DomEvent.on(this._contentNode, 'mousewheel', L.DomEvent.stopPropagation);
            this._tip = L.DomUtil.create('div', `${prefix}-tip ${prefix}-tip-${this.options.position}`, this._tipContainer);
        }

        if (this.options.closeButton) {
            closeButton = this._closeButton = L.DomUtil.create('a', `${prefix}-close-button${closeButtonCss}`, container);
            closeButton.href = '#close';
            closeButton.innerHTML = '&#215;';
            L.DomEvent.on(closeButton, 'click', this._closeMapPopups, this);
        }
    }

    _closeMapPopups(e) {
        this._map.closePopup();
        L.DomEvent.stop(e);
    }

    _updatePosition() {
        var pos = this._map.latLngToLayerPoint(this._latlng),
            is3d = L.Browser.any3d,
            offset = this.options.offset;

        if (is3d) {
            L.DomUtil.setPosition(this._container, pos);
        }

        if (/s/.test(this.options.position)) {
            this._containerBottom = -this._container.offsetHeight + offset.y - (is3d ? 0 : pos.y);
        } else {
            this._containerBottom = -offset.y - (is3d ? 0 : pos.y);
        }

        if (/e/.test(this.options.position)) {
            this._containerLeft = offset.x + (is3d ? 0 : pos.x);
        }
        else if (/w/.test(this.options.position)) {
            this._containerLeft = -Math.round(this._containerWidth) + offset.x + (is3d ? 0 : pos.x);
        }
        else {
            this._containerLeft = -Math.round(this._containerWidth / 2) + offset.x + (is3d ? 0 : pos.x);
        }

        this._container.style.bottom = this._containerBottom + 'px';

        if (this._isMobile) {
            // hide the popup pin
            this._container.children[0].children[0].style.display = "none";

            // horizontally center the popup on the screen
            const pos = this._map.latLngToContainerPoint(this._latlng),
                mapSize = this._map.getSize();

            const left = pos.x - Math.round((mapSize.x - this._containerWidth) / 2);
            this._container.style.left = `-${left}px`;

        } else {
            this._container.style.left = this._containerLeft + 'px';
        }
    }
}
