﻿import telemetry from '../../telemetry/application-insights';
import attributes from '../../constants/attributes';

const constructor = (options) => {
    const id = options.id;

    if (!options.objectIdAttribute) options.objectIdAttribute = "OBJECTID";

    let authorityProviderBase = {
        _state: null,

        doSearch: function (keyword, resource, state) {
            this._state = state;
            const uri = `${options.endpoint}${options.query}`;

            return new Promise((resolve, reject) => {
                $.get(uri, data => {
                    let jsonData = '';
                    try {
                        jsonData = JSON.parse(data);
                    } catch (e) {
                        if (typeof data !== "object") {
                            telemetry.logError(e, `${e.name} - ${e.message}`);
                            reject(e.message);
                            return;
                        }
                        jsonData = data;
                    }

                    resolve(this.filterData(jsonData, keyword, resource));
                }).fail(() => {
                    let errorMessage = `Failed to get ${options.title}`;
                    if (telemetry.logError) telemetry.logError(null, errorMessage);
                    reject(errorMessage);
                });
            });
        },

        filterData: function (list, keyword, resource) {
            let filteredList = list.features
                .filter(x => x.attributes[options.englishNameAttribute].toLowerCase().includes(keyword.toLowerCase()) || (x.attributes[options.welshNameAttribute] && x.attributes[options.welshNameAttribute].toLowerCase().includes(keyword.toLowerCase())))
                .map(x => {
                    return {
                        text: {
                            english: x.attributes[options.englishNameAttribute],
                            welsh: x.attributes[options.welshNameAttribute] ? x.attributes[options.welshNameAttribute] : x.attributes[options.englishNameAttribute]
                        },
                        selected: this.itemSelected.bind({
                            state: this._state,
                            getData: this.getData,
                            objectId: x.attributes[options.objectIdAttribute]
                        })
                    };
                });

            const totalCount = filteredList.length;
            if (filteredList.length > 4) filteredList = filteredList.splice(0, 4);

            let showTotalMessage = '';
            if (filteredList.length < totalCount) {
                showTotalMessage = resource.SearchResultsCategoryListStats.replace('{start}', filteredList.length).replace('{total}', totalCount);
            }

            return {
                id: options.id,
                title: options.title,
                results: filteredList,
                showTotalMessage: showTotalMessage
            };
        },

        itemSelected: function (map, callback) {
            if (callback) callback();

            map.toggleLoading(true);

            const objectId = this.objectId;
            this.getData(map, objectId, this.state);
        },

        getData: function(map, objectId, state) {
            const uri = `${options.endpoint}/query?f=json&objectIds=${objectId}&outFields=&outSR=27700&spatialRel=esriSpatialRelIntersects`;

            $.get(uri, data => {
                let jsonData = '';
                try {
                    jsonData = JSON.parse(data);
                } catch (e) {
                    if (typeof data !== "object") {
                        if (telemetry.logError) {
                            telemetry.logError(e, `${e.name} - ${e.message}`);
                            telemetry.logError(e, data);
                        }
                        map.toggleLoading(false);
                        return;
                    }
                    jsonData = data;
                }

                const geometry = {
                    coordinates: jsonData.features[0].geometry.rings,
                    type: 'MultiPolygon'
                };

                // need to record the polygon that has been selected
                state.addAuthority(objectId, options.id);
                const poly = map.convertToPolygon(geometry, true);
                map.addPolygonToSearchLayer(poly);
                map.centreOnPoint({ extent: poly.getBounds() });

                map.toggleLoading(false);
            }).fail(() => {
                map.toggleLoading(false);
                if (telemetry.logError) {
                    let errorMessage = `Failed to get ${options.title} by object id ${objectId}`;
                    telemetry.logError(null, errorMessage);
                }
            });
        }

    };

    return authorityProviderBase;
}

export default constructor;
