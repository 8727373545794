﻿
const furtherInformation = {

    riverLevelsLink: null,

    init: function () {
        this.riverLevelsLink = document.querySelector('.further-information-links a.river-levels');
    },

    setRiverLevelsUrl: function (coordinates) {

        if (!this.riverLevelsLink) {
            return;
        }

        const link = this.riverLevelsLink.dataset.base;
        this.riverLevelsLink.setAttribute('href', `${link}&layers=river&extent=${coordinates}`);
    },

    resetRiverLevelsUrl: function () {
        if (!this.riverLevelsLink) {
            return;
        }

        const link = this.riverLevelsLink.dataset.base;
        this.riverLevelsLink.setAttribute('href', `${link}`);
    }
}

export default furtherInformation;
