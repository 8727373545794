﻿import regex from '../constants/regex';
import constants from '../constants/mapConstants';

const mapUtils = {

    isEastingNorthing: function (value) {
        const eastingNorthingRegex = RegExp(regex.search.eastingNorthing);
        const eastingNorthingMatch = value.match(eastingNorthingRegex);

        return eastingNorthingMatch;      
    },

    isGridReference: function (value) {
        const gridReferneceRegex = RegExp(regex.search.ngr);
        const gridReferneceMatch = value.match(gridReferneceRegex);

        return gridReferneceMatch;
    },

    convertNgrToEastingNorthing: function(value) {
        const gridReferneceMatch = this.isGridReference(value);
        const eastingNorthingMatch = this.isEastingNorthing(value);

        // Check for unrecognisable value
        if (!gridReferneceMatch && !eastingNorthingMatch) {
            return { error: true }
        }

        if (eastingNorthingMatch) {
            return { easting: Number(eastingNorthingMatch[1]), northing: Number(eastingNorthingMatch[2]) }
        }

        // get numeric values of letter references, mapping A->0, B->1, C->2, etc:
        let l1 = value.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0); // 500km square
        let l2 = value.toUpperCase().charCodeAt(1) - 'A'.charCodeAt(0); // 100km square

        // shuffle down letters after 'I' since 'I' is not used in grid:
        if (l1 > 7) l1--;
        if (l2 > 7) l2--;

        // convert grid letters into 100km-square indexes from false origin (grid square SV):
        const e100km = ((l1 - 2) % 5) * 5 + (l2 % 5);
        const n100km = (19 - Math.floor(l1 / 5) * 5) - Math.floor(l2 / 5);

        // skip grid letters to get numeric (easting/northing) part of ref
        let en = value.slice(2).trim().split(/\s+/);

        // if e/n not whitespace separated, split half way
        if (en.length == 1) en = [en[0].slice(0, en[0].length / 2), en[0].slice(en[0].length / 2)];

        // validation
        if (en[0].length != en[1].length) {
            return { error: true, msg: `Invalid grid reference '${value}'` }
        }

        // standardise to 10-digit refs (metres)
        en[0] = en[0].padEnd(5, '0');
        en[1] = en[1].padEnd(5, '0');

        const e = e100km + en[0];
        const n = n100km + en[1];

        return { easting: Number(e), northing: Number(n) };
    },

    isWithinWales: function (latitude, longitude, successCallback, failureCallback) {
        const uri = `/api/search/locality/${latitude},${longitude}`;

        $.get(uri)
        .done((isWithinWales) => {
            successCallback(isWithinWales);
        }).fail(() => {
            failureCallback()
        });
    },

    isWithinWalesExtent: function (geometry) {
        return constants.WalesExtent.pad(2).contains(geometry);
    }
}

export default mapUtils;
