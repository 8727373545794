﻿import constants from "./../constants/mapConstants";
import templates from "../constants/templates";
import attributes from "../constants/attributes";
import furtherInformation from './furtherInformation';
import { keys } from "../constants/keyCodes";
import moment from "moment";
import { TiledMapLayer } from 'esri-leaflet';
import EasyButton from "./easyButton"
import Proj from "proj4leaflet";
import clusterGroup from './map/clusterGroup';
// this appears to not be used. However it is. DO NOT REMOVE this line of code
import gestureHandling from "../Extensions/GestureHandling/leafletGestureHandling";
import CustomMarker from "../Extensions/Marker";
import intersects from '../Extensions/turf/turfintersect';
import turfHelpers from '../Extensions/turf/turfhelpers';
import generalUtils from '../Util/generalUtils';

const map = (function() {
    var _turf = {
        intersects: intersects,
        helpers: turfHelpers
    };
    var _mapElement = null;
    var _mapContainerId = constants.container.mainId;
    var _disablePopups = false;
    var _showOutOfForceWarnings = false;
    var _liveMessages = [];
    var _fwaCodes = [];
    var _fwaCodesWithDetailedPolygons = [];
    var _clusteringDisabled = false;
    var _zoomLevelToShowDetailedPolygons = 13;
    var _eventKeys = {
        warningsFoundinExtent: 'warningsFoundInExtent',
        mapUnavailable: 'mapUnavailable',
        searchInvalidated: 'searchInvalidated',
        mapLoaded: 'mapLoaded',
        poylgonsLoaded: 'poylgonsLoaded'
    };
    var _enablePolygonHighlighting = false;
    var _baseZoomLevel = 0;
    var _minZoom = 0;
    var _searchPerformed = false;
    var _searchZoom = 0;
    var _searchExtent = null;
    var _mapExtent = null;
    var _basemap = null;
    var _eventListeners = [];
    var _crs = null;
    var _state = null;
    var _firstLoad = true;
    var _clusterGroup = null;
    var _highlightedFwaCode = null; //if set, this singles out one fwa code, intended for the deails page, this code is highlighted
    var _overrideMarkerClick = null;
    
    function init(options) {
        if (options.mapContainerId) _mapContainerId = options.mapContainerId;
        if (options.disablePopups) _disablePopups = options.disablePopups;
        _mapExtent = options.extent;
        _liveMessages = options.liveMessages;
        _fwaCodes = options.fwaCodes;
        _clusteringDisabled = options.clusteringDisabled;
        _enablePolygonHighlighting = options.enablePolygonHighlighting;
        _showOutOfForceWarnings = options.showOutOfForceWarnings;
        _state = options.state;
        _highlightedFwaCode = options.highlightedFwaCode;

        _basemap = new TiledMapLayer({
            url: options.basemapUrl
        });

        _basemap.metadata(onBasemapReady);

        _overrideMarkerClick = options.overrideMarkerClick;
    }

    function handleMapUnavailable() {
        _mapElement = {
            symbolData: getList()
        };
        getAllWarningsAndRaiseEvent();

        triggerEvent(_eventKeys.mapUnavailable);
    }

    function fitBounds(extent) {
        _mapElement.fitBounds(extent);
        // Reset min zoom. Have found in the past that without this the missing marker bug re-appears
        _mapElement.setMinZoom(_minZoom);
    }

    function onBasemapReady(error, metadata) {
        if (error) {
            //map is unavailable
            if (window.logError) window.logError(error, "Failed to load the basemap");
            handleMapUnavailable();
            return;
        }

        var resolutions = metadata.tileInfo.lods.map(x => x.resolution);

        var crs = new Proj.CRS(
            'EPSG:27700',
            '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs',
            {
                resolutions,
                origin: [metadata.tileInfo.origin.x, metadata.tileInfo.origin.y]
            }
        );
        
        _crs = crs;
        crs.unproject;

        let zoomSnap = constants.map.zoom.snap;
        let extent = _mapExtent;
        if (extent.projectionRequired) {
            extent = new L.LatLngBounds(crs.unproject({ y: extent.northEast.y, x: extent.northEast.x }), crs.unproject({ y: extent.southWest.y, x: extent.southWest.x }));
            _mapExtent = extent;
            zoomSnap = 1; // set to default value for details page to ensure polygon fits within map bounds.
        }

        var map = window.map = _mapElement = new L.Map(
            _mapContainerId,
            {
                crs,
                maxBounds: extent,
                renderer: L.canvas(),
                gestureHandling: true,
                zoomSnap: zoomSnap,
                zoomDelta: constants.map.zoom.delta,
                minZoom: constants.map.zoom.min,
                doubleClickZoom: false,
                maxZoom: 18 //double clicking below this takes you to Manchester because of a bug in Leaflet
            }
        );

        map.zoomControl.disable();
        map.fitBounds(extent);
        // the actual extent on the map is slightly different to that of the values we gave, so we'll
        // get them again here for greater accuracy
        _mapExtent = map.getBounds();
        map.setMaxBounds(_mapExtent);

        _minZoom = _mapElement._zoom;
        _baseZoomLevel = map.getZoom();
        _basemap.addTo(map);

        const minZoomToSet = map._zoom;

        renderSymbols(map, crs);
        getPolygons(map, crs);

        map.complexMode = true;
        map.noPolygons = true;

        let easyButton = EasyButton(templates.getMapIconTemplate(), homeButtonClickEvent);
        easyButton.addTo(map);
        easyButton.disable();
        
        map.on(attributes.events.keyup, (source) => {
            const event = source.originalEvent;

            if (!keys.isArrowKey(event.keyCode)) {
                return;
            }

            refreshMap();
        });

        addEventListener(_eventKeys.poylgonsLoaded, () => {
            easyButton.enable();
            map.zoomControl.enable();
            
            // only register the moveend event for refreshing the map when we have loaded our basic polygons
            map.on(attributes.events.moveend, (e) => {
                refreshMap();
            });
        });
        
        triggerEvent(_eventKeys.mapLoaded);
        
        // Min zoom needs to be set last of all for some reason - any earlier and some markers are not showing!
        //because we can't determine the zoom of the TA on the details page we have to force this after zooming
        //todo: there will need to be a property that overrides this
        map.setMinZoom(minZoomToSet);
    }

    function homeButtonClickEvent(btn, map) {
        triggerEvent(_eventKeys.searchInvalidated);
        map.closePopup();
        map.fitBounds(_mapExtent);
    }

    function refreshDataSource(messages, fwaCodes, dontRaiseEventAfterRenderingSymbolData) {
        _fwaCodesWithDetailedPolygons = []; //clear the detailed polygons we've already received 
        _liveMessages = messages;
        _fwaCodes = fwaCodes;

        renderSymbols(_mapElement, _crs, dontRaiseEventAfterRenderingSymbolData);
        getPolygons(_mapElement, _crs);
    }

    function refreshMap() {
        toggleDetailedPolygons(_mapElement);
        getWarningsInViewAndRaiseEvent();

        if (!isMaxZoomLevel()) {
            furtherInformation.setRiverLevelsUrl(getOsgbCoordinates());
        } else {
            furtherInformation.resetRiverLevelsUrl();
        }

        // if the current zoom level is the maximum zoomed out,
        // then the view has just been reset and not searched on
        // therefore clear _searchPerformed and do not add extent to state object
        if (_state) {
            if (isMaxZoomLevel()) {
                _state.clear();
            } else {
                _state.addCentre(_mapElement.getCenter(), _mapElement.getZoom());
            }
        }
        
        if (_searchPerformed && !getActiveSearchPolygon()) {
            _searchExtent = _mapElement.getBounds();
            _searchPerformed = false;

            // search zoom needs to be determined at 2 points. Whichever one is greater, is the search zoom as zoomed to the point
            const tempZoom = _mapElement.getZoom();

            if (tempZoom > _searchZoom) {
                _searchZoom = tempZoom;
            }
        }

        if (_searchExtent) {
            var clearSearchResults = false;
            let currentZoom = _mapElement.getZoom();
            let currentBounds = _mapElement.getBounds()

            if (_searchZoom - 3 >= currentZoom && currentBounds.contains(_searchExtent)) {
                clearSearchResults = true;
            }
            
            if (!currentBounds.intersects(_searchExtent)) {
                clearSearchResults = true;
            }

            // check for having panned/zoomed away from search location
            if (clearSearchResults) {
                triggerEvent(_eventKeys.searchInvalidated);
                _searchExtent = null;
                _searchZoom = 0;
            }
        }
    }

    function isMaxZoomLevel() {
        return _baseZoomLevel === _mapElement.getZoom();
    }

    function getAllWarningsAndRaiseEvent() {
        var map = _mapElement;
        triggerEvent(_eventKeys.warningsFoundinExtent, { symbolData: map.symbolData, fwaCodesInAndAroundExtent: map.symbolData.map(x => x.fwaCode) });
    }

    function getWarningsInViewAndRaiseEvent() {
        var map = _mapElement;
        let visibleSymbols = [];
        let fwaCodes = [];

        const mapBounds = createPolygonFromBounds(map.getBounds()).toGeoJSON();
        let searchBounds = null;

        toggleLoading(true);

        //if search is enabled we want to show everything inside the search polygon, not the map container
        const activeSearchPolygon = getActiveSearchPolygon();
        if (activeSearchPolygon) {
            searchBounds = activeSearchPolygon.toGeoJSON();
        }

        map.symbolData.forEach(data => {
            let polygonBounds = null;
            let intersects = false;
            let partialIntersect = false;

            if (data.graphic.simplePolygon) {
                polygonBounds = data.graphic.simplePolygon.toGeoJSON();
    
                //in the case where the geometry has multiple polygons, get each polygon and check independently
                polygonBounds.geometry.coordinates.forEach(coordinates => {
                    const polygon = new _turf.helpers.polygon([coordinates]);

                    try {
                        let intersection = null;
                        const searchIsActive = searchBounds ? true : false;

                        //if there is a search polygon active then also check that
                        if (searchIsActive) {
                            intersection = _turf.intersects(polygon, searchBounds);
                           
                            if (intersection != null) {
                                partialIntersect = !(coordinates.length === intersection.geometry.coordinates[0].length);
                            }
                            
                            intersects = intersection ? true : intersects;
                        }

                        const searchIsActiveAndContainsTA = intersection && searchIsActive && intersects;

                        if (searchIsActiveAndContainsTA || !searchIsActive) {
                            intersection = _turf.intersects(polygon, mapBounds);
                            if (intersection && !searchIsActive) {
                                intersects = true;
                            } else if (searchIsActive) {
                                intersects = searchIsActiveAndContainsTA && intersection;
                            }
                        }
                    } catch (e) {
                        console.error(e);
                    }
                });
            }

            if (partialIntersect && intersects && data.graphic.complexPolygon) {
                const complexPolys = data.graphic.complexPolygon.toGeoJSON();
                intersects = false;

                complexPolys.geometry.coordinates.forEach(coordinates => {
                    const polygon = new _turf.helpers.polygon([coordinates]);

                    try {
                        let intersection = null;
                        const searchIsActive = searchBounds ? true : false;

                        //if there is a search polygon active then also check that
                        if (searchIsActive) {
                            intersection = _turf.intersects(polygon, searchBounds);
                            
                            intersects = intersection ? true : intersects;
                        }
                       
                    } catch (e) {
                        console.error(e);
                    }
                });
            }

            if (!data.graphic || !data.graphic.polygonBounds || intersects) { 
                visibleSymbols.push(data);
                
                fwaCodes.push(data.fwaCode);

                if (data.graphic && !map.symbolGroup.hasLayer(data.graphic) && data.severity < 4) {
                    //if the warning exists in the view but it does not exist in the layer then add it and its polygons
                    map.symbolGroup.addLayer(data.graphic);
                    
                    togglePolygonForTargetArea(data, false, _mapElement.SimplePolygonLayer);
                    togglePolygonForTargetArea(data, false, _mapElement.ComplexPolygonLayer);
                }
            } else {
                if (data.graphic && map.symbolGroup.hasLayer(data.graphic)) {
                    //remove the graphic and its polygons from the map if it doesn't exist in the view
                    map.symbolGroup.removeLayer(data.graphic);
                    togglePolygonForTargetArea(data, true, _mapElement.SimplePolygonLayer);
                    togglePolygonForTargetArea(data, true, _mapElement.ComplexPolygonLayer);
                }
            }
        });
        triggerEvent(_eventKeys.warningsFoundinExtent, { symbolData: visibleSymbols, fwaCodesInAndAroundExtent: fwaCodes });

        toggleLoading(false);
    }

    function toggleLoading(showLoading) {
        const mapLoadingContainer = document.querySelector(`.${attributes.css.class.mapLoading}`);

        if (showLoading) {
            mapLoadingContainer.classList.add(attributes.css.class.mapIsLoading);
            return; 
        }

        mapLoadingContainer.classList.remove(attributes.css.class.mapIsLoading);
    }

    function togglePolygonForTargetArea(data, hide, layer) {
        if (!layer) return;

        let polygonType = data.graphic.simplePolygon;

        if (layer === _mapElement.ComplexPolygonLayer) {
            polygonType = data.graphic.complexPolygon;
        }

        if (data.severity < 4) {
            let index = 0;
            if (data.severity === 1) index = 2;
            if (data.severity === 2) index = 1;
            if (data.severity === 3) index = 0;
            const polygonLayer = layer.getLayers()[index];
            
            if (hide && polygonLayer.hasLayer(polygonType)) {
                polygonLayer.removeLayer(polygonType);
            }

            if (!hide && !polygonLayer.hasLayer(polygonType) && polygonType) {
                polygonLayer.addLayer(polygonType);
            }            
        }
    }

    function createPolygonFromBounds(latLngBounds) {
        var center = latLngBounds.getCenter()
        let latlngs = [];

        latlngs.push(latLngBounds.getSouthWest());//bottom left
        latlngs.push({ lat: latLngBounds.getSouth(), lng: center.lng });//bottom center
        latlngs.push(latLngBounds.getSouthEast());//bottom right
        latlngs.push({ lat: center.lat, lng: latLngBounds.getEast() });// center right
        latlngs.push(latLngBounds.getNorthEast());//top right
        latlngs.push({ lat: latLngBounds.getNorth(), lng: center.lng });//top center
        latlngs.push(latLngBounds.getNorthWest());//top left
        latlngs.push({ lat: center.lat, lng: latLngBounds.getWest() });//center left

        return new L.polygon(latlngs);
    }

    function getPolygons(map, crs) {
        $.post("/api/targetarea/geometry", `TargetAreas=${_fwaCodes}&GeometryType=0`, data => {
            loadSimplePolygons(data, crs, map, true);            
        });

        $.post("/api/targetarea/geometry", `TargetAreas=${_fwaCodes}&GeometryType=1`, data => {
            loadComplexPolygons(data, crs, map, true);
            triggerEvent(_eventKeys.poylgonsLoaded);
            toggleDetailedPolygons(map);
            getWarningsInViewAndRaiseEvent();

            if (_firstLoad && _state) {
                _state.restoreState();
                _firstLoad = false;
            }
        });


        //handle detailed polygons
        addEventListener(_eventKeys.warningsFoundinExtent, dataInView => {
            if (_mapElement.getZoom() < _zoomLevelToShowDetailedPolygons) return;

            const fwaCodeList = dataInView.fwaCodesInAndAroundExtent.filter(fwaCode => {
                if (_fwaCodesWithDetailedPolygons.filter(existingPoly => existingPoly.fwaCode === fwaCode).length === 0) {
                    //store a list of all fwaCodes we've already asked for to prevent asking for them twice
                    _fwaCodesWithDetailedPolygons.push({ fwaCode: fwaCode, polygonLayer: null });
                    return true;
                } else {
                    return false;
                }
            });

            //if there is nothing in the extent that hasn't been downloaded before then just show what we already have
            if (fwaCodeList.length === 0) {
                showDetailedPolygonsInView(dataInView);
                return;
            }

            var fwaCodes = fwaCodeList.join(",");

            //there's more to download in the current extent that we don't already have, get them from the server
            getComplexPolygonsFromServer(fwaCodes, dataInView, crs);
        });
    }

    function showDetailedPolygonsInView(dataInView) {
        //filter the array to get only those fwaCodes which are in the current view
        let severeGroup = new L.LayerGroup();
        let warningGroup = new L.LayerGroup();
        let alertGroup = new L.LayerGroup();

        dataInView.symbolData.forEach(symbol => {
            const polygonToDisplay = _fwaCodesWithDetailedPolygons.filter(x => x.fwaCode === symbol.fwaCode);
            if (polygonToDisplay.length === 0 || polygonToDisplay[0].polygonLayer === null) return;

            if (symbol.severity === 1) severeGroup.addLayer(polygonToDisplay[0].polygonLayer);
            if (symbol.severity === 2) warningGroup.addLayer(polygonToDisplay[0].polygonLayer);
            if (symbol.severity > 2 || symbol.severity === 0) alertGroup.addLayer(polygonToDisplay[0].polygonLayer);
        });

        if (_mapElement.ComplexPolygonLayer) {
            _mapElement.ComplexPolygonLayer.addLayer(alertGroup);
            _mapElement.ComplexPolygonLayer.addLayer(warningGroup);
            _mapElement.ComplexPolygonLayer.addLayer(severeGroup);
        }

        severeGroup.setZIndex(3000);
        warningGroup.setZIndex(2000);
        alertGroup.setZIndex(1000);
    }

    function getComplexPolygonsFromServer(fwaCodes, dataInView, crs) {
        $.post("/api/targetarea/geometry", `TargetAreas=${fwaCodes}&GeometryType=1`, polygonData => {
            var layer = loadComplexPolygons(polygonData, crs, _mapElement, false);
            if (!_mapElement.ComplexPolygonLayer) _mapElement.ComplexPolygonLayer = new L.LayerGroup();

            //despite retrieving all the data we only want to show the polygons for the current view, therefore clear and check before adding
            _mapElement.ComplexPolygonLayer.clearLayers();
            layer.eachLayer(polygonLayer => {
                let fwaCodeEntries = _fwaCodesWithDetailedPolygons.filter(x => x.fwaCode === polygonLayer.fwaCode);
                if (fwaCodeEntries.length === 0) return;

               //add the polygon layer data to the existing array
               fwaCodeEntries[0].polygonLayer = polygonLayer;
            });

            showDetailedPolygonsInView(dataInView);

            toggleDetailedPolygons(_mapElement, true);
        });
    }

    function invalidate() {
        if (_mapElement) {
            _mapElement.invalidateSize();
        }
    }

    function toggleDetailedPolygons(map, refreshLayer) {
        const zoomLevelToShowSimplePolygons = 6;

        const togglePolygons = (layersToRemove, layerToAdd) => {
            if (layersToRemove && layersToRemove.length > 0) {
                layersToRemove.forEach(layer => {
                    if (layer) map.removeLayer(layer);
                });
            }

            if(layerToAdd) map.addLayer(layerToAdd);
        };

        if (map._zoom >= _zoomLevelToShowDetailedPolygons && (!map.complexMode || refreshLayer)) {
            togglePolygons([map.SimplePolygonLayer], map.ComplexPolygonLayer);
            map.complexMode = true;
            map.noPolygons = false;
        } else if (map._zoom >= zoomLevelToShowSimplePolygons && map._zoom < _zoomLevelToShowDetailedPolygons && (map.complexMode || map.noPolygons)) {
            togglePolygons([map.ComplexPolygonLayer], map.SimplePolygonLayer);
            map.complexMode = false;
            map.noPolygons = false;
        } else if (map._zoom < zoomLevelToShowSimplePolygons) {
            // Hide all polygons
            togglePolygons([map.ComplexPolygonLayer, map.SimplePolygonLayer], null);
            map.noPolygons = true;
            map.complexMode = false;
        }
    }

    function renderSymbols(map, crs, dontRaiseEventAfterRenderingSymbolData) {
        //the list comes from the server by order of most severe to least severe
        //reversing the order ensures that the most severe are rendered last
        //in leaflet, overlay is determined by the order in which the item is added
        let zIndex = 1;

        if (_clusterGroup) {
            _clusterGroup.clearLayers();
        } else {
            _clusterGroup = clusterGroup.init(_clusteringDisabled);
        }

        const symbolData = getList((listItem, liveMessage, symbol) => {
            var graphic = createSymbolGraphicForLiveMessage(liveMessage, crs, map, symbol);
            
            graphic.setZIndexOffset(zIndex);

            listItem.isHighlighted = false;
            listItem.graphic = graphic;
            listItem.cluster = _clusterGroup;
            
            zIndex++;
        });

        map.symbolGroup = _clusterGroup;

        if (!map.hasLayer(_clusterGroup)) {
            map.addLayer(_clusterGroup);
        }
        
        map.symbolData = symbolData;
        
        if (dontRaiseEventAfterRenderingSymbolData) return;
        getWarningsInViewAndRaiseEvent();
    }

    function getList(callback) {
        var data = _liveMessages;
        var symbolData = [];

        for (var i = data.length - 1; i >= 0; i--) {
            var liveMessage = data[i];
            if (liveMessage.severity > 4) continue;

            var symbol = constants.severityIconRenderer[liveMessage.severity].symbol;

            //symbol data is the datasource that feeds other elements of this page, we therefore want it to be in the same order given to us by the server.
            //therefore insert at 0 using splice rather than push to the end
            let listItem = {
                fwaCode: liveMessage.fwaCode,
                severity: liveMessage.severity,
                name: liveMessage.name,
                issued: liveMessage.issued,
                updated: liveMessage.updated,
                severityName: liveMessage.severityName,
                active: liveMessage.active,
                severityCssModifier: liveMessage.severityCssModifier,
                iconUrl: symbol.options.iconUrl,
                type: liveMessage.type,
                uri: liveMessage.uri
            };

            if (callback) callback(listItem, liveMessage, symbol);
            
            symbolData.splice(0, 0, listItem);
        }

        return symbolData;
    }

    function getFilteredList(keyword) {
        return _mapElement.symbolData
            .filter(x => x.name.english.toLowerCase().includes(keyword.toLowerCase()) || x.name.welsh.toLowerCase().includes(keyword.toLowerCase()))
            .map(x => {
                return {
                    polygonBounds: x.graphic.polygonBounds,
                    text: x.name,
                    fitToBounds: () => {
                        centreOnPoint({ extent: x.graphic.polygonBounds });
                    }
                }
            });        
    }

    function addPolygonToSearchLayer(polyline) {
        if (!_mapElement.searchLayerGroup) {
            _mapElement.searchLayerGroup = new L.LayerGroup();
            _mapElement.addLayer(_mapElement.searchLayerGroup);
        }

        _mapElement.searchLayerGroup.addLayer(polyline);

        return polyline;
    }

    function getActiveSearchPolygon() {
        if (!_mapElement.searchLayerGroup) return;

        let searchPolygon = null;

        _mapElement.searchLayerGroup.eachLayer(layer => {
            if (layer._bounds) searchPolygon = layer;
        });

        return searchPolygon;
    }

    function clearSearchLayer() {
        if (!_mapElement.searchLayerGroup) return;

        _mapElement.searchLayerGroup.eachLayer(layer => _mapElement.searchLayerGroup.removeLayer(layer));
        getWarningsInViewAndRaiseEvent();
    }

    function convertToOsGb(array, crs) {
        var len = array.length;

        if (len === 2 && typeof array[0] === "number" && typeof array[1] === "number") {
            return crs.unproject({ x: array[0], y: array[1] });
        }

        if (len > 0) {
            for (var i = 0; i < len; i++) {
                array[i] = convertToOsGb(array[i], crs);
            }
        }

        return array;
    }

    function getOsgbCoordinates() {

        var map = _mapElement,
            crs = _mapElement.options.crs;

        const wkid = crs.code.match(/(\d+)/)[0];

        const pos = map.getBounds();
        const ne = crs.project(pos._northEast);
        const sw = crs.project(pos._southWest);

        return `${sw.x}+${sw.y}+${ne.x}+${ne.y}+${wkid}`;
    }

    function convertToLatLongExtent(xMin, yMin, xMax, yMax) {
        return new L.LatLngBounds(_crs.unproject({ x: xMin, y: yMin }), _crs.unproject({ x: xMax, y: yMax }));
    }

    function loadSimplePolygons(liveMessagesAndPolygons, crs, map, bindToWarning) {
        let severeGroup = new L.LayerGroup();
        let warningGroup = new L.LayerGroup();
        let alertGroup = new L.LayerGroup();
        if (!map.SimplePolygonLayer) map.SimplePolygonLayer = new L.LayerGroup();

        map.SimplePolygonLayer.clearLayers();

        for (const property in liveMessagesAndPolygons) {
            const polygon = getPolygon(property, liveMessagesAndPolygons, crs, map, bindToWarning, constants.geometryTypes.simple);
            
            if (polygon.options.severity === 1) severeGroup.addLayer(polygon);
            if (polygon.options.severity === 2) warningGroup.addLayer(polygon);
            if (polygon.options.severity > 2) alertGroup.addLayer(polygon);
        }

        map.SimplePolygonLayer.addLayer(alertGroup);
        map.SimplePolygonLayer.addLayer(warningGroup);
        map.SimplePolygonLayer.addLayer(severeGroup);

        severeGroup.setZIndex(3000);
        warningGroup.setZIndex(2000);
        alertGroup.setZIndex(1000);
    }

    function loadComplexPolygons(liveMessagesAndPolygons, crs, map, bindToWarning) {
        var polygons = [];
        for (const property in liveMessagesAndPolygons) {
            const polygon = getPolygon(property, liveMessagesAndPolygons, crs, map, bindToWarning, constants.geometryTypes.complex);
            polygons.push(polygon);
        }

        return new L.LayerGroup(polygons);
    }

    function getPolygon(fwaCode, liveMessagesAndPolygons, crs, map, bindToWarning, geometryTypeId) {
        let isMainView = map._container.id === constants.container.mainId || fwaCode !== _highlightedFwaCode;

        const liveMessageGraphics = map.symbolData.filter(x => x.fwaCode === fwaCode);
        if (!liveMessageGraphics || !liveMessageGraphics[0]) return;

        const liveMessageGraphic = liveMessageGraphics[0];
        const severity = liveMessageGraphic.severity;
        let opacity = 1,
            interactive = true;

        // The polygon is required on combined view so add polygon to map but hide it.
        if (severity === 4 && !_showOutOfForceWarnings) {
            opacity = 0;
            interactive = false;
        }

        const liveMessage = liveMessagesAndPolygons[fwaCode];
        const rings = liveMessage.rings;

        const style = constants.polygonStyleBySeverity[severity];
        let fillColour = isMainView ? style.fill : style.detailsPageFill;
        let outlineWidth = isMainView ? style.outlineWidth : style.detailsPageOutlineWidth;
        let outlineColour = isMainView ? style.outline : style.detailsPageOutline;

        if (severity === 4 || severity === 0) {
            let today = new Date();
            if (!liveMessageGraphic.updated || moment(today).diff(liveMessageGraphic.updated, "hours") > 24) {
                fillColour = style.fill;
            }
        }

        let polygon = new L.Polygon(convertToOsGb(rings, crs), {
            fillColor: `rgba(${fillColour.join(",")})`,
            fillOpacity: 1,
            opacity: opacity,
            color: `rgba(${outlineColour.join(",")})`,
            weight: outlineWidth,
            stroke: true,
            fwaCode: fwaCode,
            severity: severity,
            interactive: interactive && !_disablePopups
        });

        const marker = liveMessageGraphic.graphic;
        if (geometryTypeId === constants.geometryTypes.simple) {
            marker.setPolygon(false, polygon);
        }
        else if (geometryTypeId === constants.geometryTypes.complex) {
            marker.setPolygon(true, polygon);
        }

        if (bindToWarning) marker.polygonBounds = polygon.getBounds();

        polygon.fwaCode = fwaCode;
        return polygon;
    }

    function createSymbolGraphicForLiveMessage(liveMessage, crs, map, symbol) {
        var latlng = crs.unproject(new L.Point(liveMessage.point.x, liveMessage.point.y));

        var marker = new CustomMarker(latlng, {
            icon: symbol,
            interactive: !_disablePopups,
            keyboard: false
        });

        marker.iconUrl = symbol.options.iconUrl;
        marker.severity = liveMessage.severity;
        marker.fwaCode = liveMessage.fwaCode;

        if (liveMessage.severity === 0 || liveMessage.severity > 3) {
            marker.hide();
            return marker;
        }

        marker.on(attributes.events.click, (e) => {
            if (!marker.polygonBounds) return;

            if (_overrideMarkerClick) {
                _overrideMarkerClick(e, liveMessage);
                return;
            }

            map.once(`${attributes.events.zoomend} ${attributes.events.moveend}`, () => {
                if (marker.hasPopup(e)) return;
                marker.showPopup(map, liveMessage, latlng);
                marker.select(true);
            });

            map.fitBounds(marker.polygonBounds);
        });
        
        return marker;
    }
    
    function addEventListener(eventName, callback) {
        // only allow an event listener to be added once for each time
        if (_eventListeners.filter(e => e.eventName === eventName && e.callback.toString() === callback.toString()).length === 0) {
            _eventListeners.push({
                eventName: eventName,
                callback: callback
            });
        }
    }

    function triggerEvent(eventName, data) {
        _eventListeners.filter(x => x.eventName === eventName).forEach(event => {
            if (!event.callback) return;
            event.callback(data);
        });
    }

    function centreOnPoint(centreOptions) { // radius in m
        if (centreOptions.point) {
            let latitudeCenter = centreOptions.point.latitude;
            let longitudeCenter = centreOptions.point.longitude;
            let r = centreOptions.point.radius;

            let distanceFromCentre = r / 1000 / 2; // distance from the centre point in km - where the radius was supplied in metres
            let oneDegree = 88.596; // one degree in km - approximate, but good enough for the extent of wales
            let percentageOneDegree = distanceFromCentre / oneDegree;
    
            // north-east corner of square
            let latitudeNE  = latitudeCenter  + percentageOneDegree;
            let longitudeNE = longitudeCenter + percentageOneDegree;
    
            // south-west corner of square
            let latitudeSW  = latitudeCenter  - percentageOneDegree;
            let longitudeSW = longitudeCenter - percentageOneDegree;    

            fitBounds([
                [latitudeSW, longitudeSW], 
                [latitudeNE, longitudeNE]]);
        } else if (centreOptions.centreAndZoom) {
            _mapElement.setView(centreOptions.centreAndZoom.c, centreOptions.centreAndZoom.z);
        } else {
            fitBounds(centreOptions.extent);
        }
        
        // get the zoom level BEFORE zoom finishes. We want the initial zoom level
        _searchZoom = _mapElement.getZoom();
        _searchPerformed = true;
    }

    function convertToLatLongPoint(x, y) {
        const latlng = _crs.unproject({ x: x, y: y });
        return new L.LatLng(latlng.lat, latlng.lng);
    }

    function convertToPolygon(geometry, isDottedLineStyle) {
        const points = geometry.coordinates ? geometry.coordinates : geometry;
        let options = {};
        if (isDottedLineStyle) {
            options = constants.dottedLineStyle;
        }

        return new L.Polygon(convertToOsGb(points, _crs), options);
    }


    function resetView() {
        _searchPerformed = false;
        centreOnPoint({ extent: _mapExtent });
    }

    return {
        init: init,
        centreOnPoint: centreOnPoint,
        isMaxZoomLevel: isMaxZoomLevel,
        eventKeys: _eventKeys,
        getLiveMessageCount: () => { return _liveMessages.length },
        addEventListener: addEventListener,
        clearSearchLayer: clearSearchLayer,
        getFilteredList: getFilteredList,
        convertToLatLongPoint: convertToLatLongPoint,
        convertToLatLongExtent: convertToLatLongExtent,
        convertToPolygon: convertToPolygon,
        addPolygonToSearchLayer: addPolygonToSearchLayer,
        resetView: resetView,
        toggleLoading: toggleLoading,
        invalidate: invalidate,
        refreshDataSource: refreshDataSource,
        getWarningsInViewAndRaiseEvent: getWarningsInViewAndRaiseEvent,
        getOsgbCoordinates: getOsgbCoordinates,
        fitBounds: fitBounds
    }
})();

export default map;
