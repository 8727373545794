﻿import { StationListView, StationDetailComponent, Tooltip } from '@nrw.core/styles';
import { Resource } from "../Util/resource";
import moment from 'moment';
import $ from 'jquery';
import telemetry from '../telemetry/application-insights';

const RiverStations = (function () {

    const StationListViewBuilder = (function () {

        function build(riverLevelsUri) {

            const LinkBuilder = {
                linkToGraph: (station, parameterType) => {
                    if (station.url === null) {
                        let url = `/Station/${station.id}`;

                        var langRegex = new RegExp(/\/\?lang=(en|cy)/);
                        const langResult = langRegex.exec(riverLevelsUri);
                        const lang = langResult.length > 0 ? langResult[0] : '';
                        url = riverLevelsUri.replace(langRegex, `${url}${lang}`);

                        if (typeof parameterType !== typeof undefined) {
                            url += `&parameterType=${parameterType}`;
                        }                        

                        return url;
                    }
                    return station.url;
                }
            };

            const stationDetailComponentOptions = {
                LinkBuilder: LinkBuilder,
                moment: moment,
                Constants: {
                    TRANSPARENT_PIXEL_DATA_URI: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQI12P4DwQACfsD/WMmxY8AAAAASUVORK5CYII='
                },
                text: {
                    lessData: Resource.LessData,
                    viewLessData: Resource.ViewLessData,
                    moreData: Resource.MoreData,
                    viewMoreData: Resource.ViewMoreData,
                    viewGraph: Resource.ViewGraph,
                    graph: Resource.Graph,
                    upToDateReadingNotAvailable: Resource.UpToDateReadingNotAvailable,
                    rainfallHelpText: Resource.RainfallHelpText,
                    viewRiverLevelGraph: Resource.ViewRiverLevelGraph,
                    viewRainfallLevelGraph: Resource.ViewRainfallLevelGraph,
                    viewTidalLevelGraph: Resource.ViewTidalLevelGraph,
                    offline: Resource.Offline,
                    levelFalling: Resource.LevelFalling,
                    levelSteady: Resource.LevelSteady,
                    levelRising: Resource.LevelRising,
                    rainfallDry: Resource.RainfallDry,
                    rainfallLightRain: Resource.RainfallLightRain,
                    rainfallModerateRain: Resource.RainfallModerateRain,
                    rainfallHeavyRain: Resource.RainfallHeavyRain
                }
            };

            return new StationListView({
                $scrollingContainer: $('.river-stations-container__list'),
                stationDetailComponent: StationDetailComponent,
                pageNumberLabel: Resource.PageNumber,
                $: $,
                stationDetailComponentOptions: stationDetailComponentOptions
            });

        }        

        return {         
            build: build
        }
    })();
    

    function init(fwaCode, riverLevelsUri) {
        let stationListView = StationListViewBuilder.build(riverLevelsUri);

        function initialiseStationsList(stations) {
            stationListView.pagination.enabled = false;
            stationListView.clearStations();
            stationListView.addStations(stations);
            stationListView.updateAllStationsRelevance(stations, null);
        }

        $.get(`/api/detail/getRiverStations?fwaCode=${fwaCode}`).done(data => {
            data = data.map(x => {
                x.parameters = x.mappedParameters;
                return x;
            });
            initialiseStationsList(data);
        }).fail(error => {
            initialiseStationsList([]);
            document.querySelector('.station-list__stats').innerHTML = Resource.RiverServiceUnavailable
            console.log(error);
            telemetry.logError(error);
        });

        //initialises tooltips on elements which have data-tooltip="" - this is what river stations uses to show tooltips
        Tooltip.init();
    }    

    return {
        init: init
    };

})();

export default RiverStations;

