﻿import authorityProviderBase from '../searchProviders/authorityProviderBase';
import { Resource } from "../../Util/resource";
import { loadResources } from "../../Util/resource";

loadResources();

const operationalRegions = authorityProviderBase({
    id: 'operationalRegions',
    title: Resource.SearchResultsOperationalRegions,
    endpoint: '/maplayerproxy/Search/FeatureServer/0',
    englishNameAttribute: 'NAME',
    welshNameAttribute: 'ENW',
    objectIdAttribute: 'OBJECTID_1',
    query: '/query?f=json&outFields=NAME%2CENW%2COBJECTID_1&outSR=27700&returnGeometry=false&spatialRel=esriSpatialRelIntersects&where=1%3D1'
});

const policeAuthorities = authorityProviderBase({
    id: 'policeAuthorities',
    title: Resource.SearchResultsPoliceAuthorities,
    endpoint: '/maplayerproxy/Search/FeatureServer/1',
    englishNameAttribute: 'NAME',
    welshNameAttribute: 'ENW',
    query: '/query?f=json&outFields=NAME%2CENW%2COBJECTID&outSR=27700&returnGeometry=false&spatialRel=esriSpatialRelIntersects&where=1%3D1'
});

const localAuthorities = authorityProviderBase({
    id: 'localAuthorities',
    title: Resource.SearchResultsLocalAuthority,
    endpoint: '/maplayerproxy/Search/FeatureServer/2',
    englishNameAttribute: 'NAME',
    welshNameAttribute: 'ENW',
    query: '/query?f=json&outFields=NAME%2CNAME%2COBJECTID&outSR=27700&returnGeometry=false&spatialRel=esriSpatialRelIntersects&where=1%3D1'
});

const nrwDutyRoleAreas = authorityProviderBase({
    id: 'nrwDutyRoleAreas',
    title: Resource.SearchResultsNRWDutyRoleAreas,
    endpoint: '/maplayerproxy/Search/FeatureServer/3',
    englishNameAttribute: 'Area_Name',
    welshNameAttribute: 'Enw',
    query: '/query?f=json&outFields=Area_Name%2CEnw%2COBJECTID&outSR=27700&returnGeometry=false&spatialRel=esriSpatialRelIntersects&where=1%3D1'
});

const fireAuthorities = authorityProviderBase({
    id: 'fireAuthorities',
    title: Resource.SearchResultsFireAuthorities,
    endpoint: '/maplayerproxy/Search/FeatureServer/4',
    englishNameAttribute: 'fra17nm',
    welshNameAttribute: 'ENW',
    objectIdAttribute: 'OBJECTID_1',
    query: '/query?f=json&outFields=fra17nm%2CENW%2COBJECTID_1&outSR=27700&returnGeometry=false&spatialRel=esriSpatialRelIntersects&where=1%3D1'
});

const allAuthorities = {
    operationalRegions: operationalRegions,
    policeAuthorities: policeAuthorities,
    localAuthorities: localAuthorities,
    nrwDutyRoleAreas: nrwDutyRoleAreas,
    fireAuthorities: fireAuthorities
}

export { policeAuthorities, localAuthorities, nrwDutyRoleAreas, fireAuthorities, operationalRegions, allAuthorities };
