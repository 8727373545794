﻿import accordion from '../components/accordion';
import map from '../components/map';
import modal from '../components/modal';
import furtherInformation from '../components/furtherInformation';
import refresh from '../components/refresh';
import messageSeverity from "../constants/messageSeverity";
import attributes from "../constants/attributes";
import generalUtils from "../Util/generalUtils";
import riverStations from '../components/riverStations';
import dataStorage from '../persistence/data-storage'; 

const Page = {

    message: null,
    copiedAnimationLock: false,
    togglePolygonData: null,

    build: function (liveMessageJson, detailsJson, basemapUrl, riverLevelsUri, riverStationsDisabled) {
        if (!liveMessageJson) return;

        let liveMessages = JSON.parse(liveMessageJson);
        if (!liveMessages || !liveMessages.messages || liveMessages.messages.length === 0) {
            liveMessages = {
            }
        };

        const details = JSON.parse(detailsJson);
        if (!details || !details.extent) return;

        this.message = details;
        const tempExtent = this.message.extent;

        //default to only show the highlighted TA, therefore ignore the others in liveMessages, they will be used when the user asks for them
        liveMessages.messages = [this.message];

        const extent = {
            northEast: {
                x: tempExtent.xmax,
                y: tempExtent.ymax
            },
            southWest: {
                x: tempExtent.xmin,
                y: tempExtent.ymin
            },
            projectionRequired: true
        };

        let fwaCodes = [this.message.fwaCode];

        const overrideMarkerClick = (e, liveMessage) => {
            if (this.message.fwaCode === liveMessage.fwaCode) return;

            location.href = `/Detail/${liveMessage.fwaCode}`;
        };
        
        map.init({
            extent: extent,
            liveMessages: liveMessages.messages,
            fwaCodes: fwaCodes,
            basemapUrl: basemapUrl,
            clusteringDisabled: true,
            mapContainerId: 'detailsDiv',
            disablePopups: false,
            enablePolygonHighlighting: true,
            showOutOfForceWarnings: true,
            highlightedFwaCode: this.message.fwaCode,
            overrideMarkerClick: overrideMarkerClick
        });

        const togglePolygonsButton = document.querySelector(attributes.css.id.togglePolygons);
        togglePolygonsButton.addEventListener(attributes.events.change, e => {
            this.togglePolygonData = this.getAllPolygonData(liveMessageJson);
            if (e.target.checked) {
                map.refreshDataSource(this.togglePolygonData.warningAndAlertMessages, this.togglePolygonData.fwaCodes, true);
            } else {
                map.refreshDataSource(this.togglePolygonData.warningAndAlertMessages.filter(x => x.fwaCode === this.message.fwaCode), [this.message.fwaCode], true);
            }
        });

        modal('.severity-name__info-link', '.what-to-do-modal', (modal) => {
            const severity = this.message.severity === messageSeverity.unknown ? 4 : this.message.severity;
            const modalData = modal.querySelector(`.what-to-do-modal__severities [data-severity='${severity}']`);
    
            modalData.classList.remove(attributes.css.class.displayNone);
            modalData.setAttribute(attributes.aria.hidden, attributes.global.false);
    
            modal.setAttribute(attributes.aria.labelledBy, `${modalData.dataset.idPrefix}__title`);
            modal.setAttribute(attributes.aria.describedBy, `${modalData.dataset.idPrefix}__description`);
        });
        modal('.details__share-button', '.share-data-modal', (modal) => {
            modal.querySelector('.share-data-modal__link-field').addEventListener(attributes.events.click, (e)=> {
                generalUtils.copyTextToClipboard(e.target.innerText);
                
                if (!this.copiedAnimationLock) {
                    this.copiedAnimationLock = true;
                    $(".share-data-modal__link-field").addClass("share-data-modal__link-field--copied");
                    setTimeout(() => {
                        $(".share-data-modal__link-field").removeClass("share-data-modal__link-field--copied");
                        this.copiedAnimationLock = false;
                    }, 1000);
                }
            });
        });

        accordion('.related-areas-accordion');
        accordion('.previous-statuses-accordion');
        accordion('.accordion-area');
        if (riverStationsDisabled === 'False') {
            accordion('.river-stations-accordion');
            riverStations.init(this.message.fwaCode, riverLevelsUri);
        }

        furtherInformation.init();
        map.addEventListener(map.eventKeys.mapLoaded, () => {
            furtherInformation.setRiverLevelsUrl(map.getOsgbCoordinates());
        });

        map.addEventListener(map.eventKeys.warningsFoundinExtent, () => {
            togglePolygonsButton.removeAttribute(attributes.css.dom.disabled);
        });
        
        refresh.init((updateRequired) => {
            if (updateRequired) {
                location.reload();
            }
        });        
    },

    getAllPolygonData: function(liveMessageJson) {
        if (this.togglePolygonData) return this.togglePolygonData;
               
        let liveMessages = JSON.parse(liveMessageJson);
        if (liveMessages.messages.filter(x => x.fwaCode === this.message.fwaCode).length === 0) {
            liveMessages.messages.push(this.message);
        }

        // show only warnings & alerts and if focused on a "no longer in force" TA ensure that TA is not removed.
        const warningAndAlertMessages = liveMessages.messages.filter(x => x.severity > 0 && x.severity < 4 || x.fwaCode === this.message.fwaCode);
        const fwaCodes = warningAndAlertMessages.map(x => x.fwaCode);

        return {
            warningAndAlertMessages: warningAndAlertMessages,
            fwaCodes: fwaCodes
        };
    },

    setData: function(liveMessages) {
        dataStorage.setLiveMessages(liveMessages);
    },
};

export { Page };
