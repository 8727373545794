﻿const notification = {
    notificationContainer: document.querySelector(".browser-support-notification"),
    notificationMessageTechnical: document.querySelector(".browser-support-notification__text--technical-issues"),

    showError: function () {
        this.notificationContainer.classList.remove("display-none");
        this.notificationContainer.classList.add("browser-support-notification--error");
        this.notificationMessageTechnical.classList.remove("display-none");
    }
};

export default notification;
