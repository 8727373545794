﻿import attributes from '../constants/attributes';
import regex from '../constants/regex';
import mapUtils from '../Util/mapUtils';
import townsAndPlaces from '../components/searchProviders/townsAndPlaces';
import rivers from '../components/searchProviders/rivers';
import telemetry from '../telemetry/application-insights';
import searchResults from '../components/searchResults';
import targetAreas from './searchProviders/targetAreas';
import { policeAuthorities, localAuthorities, nrwDutyRoleAreas, fireAuthorities, operationalRegions } from './searchProviders/localAuthorities';
import { deviceListener, device } from '../Util/deviceListener';
import { keyCodes, keys } from '../constants/keyCodes';
import postcodeSearch from './searchProviders/postcode';
import ngrSearch from './searchProviders/ngr';
import searchTypes from '../constants/searchTypes';
import responseCodes from '../constants/responseCodes';

const search = (function () {
    var _searchResults = null;
    var _options = null;
    var _searchInput = null;
    var _tabOccurred = false;
    var _keyUpTimeOut = null;
    var _state = null;

    function init(options) {
        _searchInput = document.querySelector(attributes.css.class.searchInput);

        // using mousedown here instead of click as it fires before blur which allows the 'click' to fire before the blur event, which itself allows
        // the current location anchor to work on mobile/tablet
        document.querySelectorAll(`.${attributes.css.class.searchLocationButton}`).forEach(btn => btn.addEventListener(attributes.events.mousedown, onCurrentPositionClick));

        // hide the current location link when losing focus from it
        document.querySelectorAll(`.${attributes.css.class.searchLocationButton}`).forEach(btn => {
            btn.addEventListener(attributes.events.blur, hideMobileCurrentLocation);
            btn.addEventListener(attributes.events.keydown, currentLocationKeyPressed);
        });

        document.querySelector(attributes.css.id.searchButton).addEventListener(attributes.events.click, onSearchClick.bind(this));

        _searchInput.addEventListener(attributes.events.focus, searchInputOnFocus);
        _searchInput.addEventListener(attributes.events.keydown, searchInputKeyDown.bind(this)); // listen for key down in input so we can check for tab being pressed
        _searchInput.addEventListener(attributes.events.blur, searchInputOnLoseFocus.bind(this));
        _searchInput.addEventListener(attributes.events.keyup, searchInputOnKeyUp.bind(this));

        searchResults.init(clearSearch, options.map, options.state, options.resource);

        //search providers init
        targetAreas.init(options.map);
        _searchResults = searchResults;
        _options = options;
        _state = options.state;
    }

    // handle key press on the current location link to determine if we need to simulate clicking on the link
    function currentLocationKeyPressed(e) {
        const keycode = e.which || e.keycode;

        if (keycode === keyCodes.spacebar) {
            e.preventDefault();
            onCurrentPositionClick();
        }
    }

    // handle key press in the search input so that we can identify if tab has been pressed
    function searchInputKeyDown(e) {
        const keycode = e.which || e.keycode;

        if (keycode === keyCodes.tab) {
            this._tabOccurred = true;
        }
    }

    function searchInputOnKeyUp(e) {
        clearSuggestionTimeout();

        const keycode = e.which || e.keycode;

        if (_searchInput.value || keycode !== keyCodes.return) {
            clearSearch({
                results: true
            });
        }
        
        if (keycode === keyCodes.tab || keycode === keyCodes.return || keycode === keyCodes.alt || keycode === keyCodes.shift || keys.isArrowKey(keycode)) {
            return;
        }

        if (keycode === keyCodes.escape) {
            searchResults.hideSearchSuggestions();
            return;
        }

        _keyUpTimeOut = setTimeout(getSearchSuggestions.bind(this), 300);
    }

    function clearSuggestionTimeout() {
        if (_keyUpTimeOut) clearTimeout(_keyUpTimeOut);
    }

    function getSearchSuggestions() {
        clearSuggestionTimeout();

        const searchTerm = _searchInput.value;
        if (!searchTerm) {
            searchResults.hideSearchSuggestions();
            return;
        }

        toggleIsSearching(true);

        doLocalitySearch(searchTerm, searchGroups => {
            searchResults.populateSearchSuggestions(searchGroups, searchTerm);
        });
    }

    function searchInputOnFocus() {
        const currentDisplay = deviceListener.getDisplay();

        if (currentDisplay === device.Mobile || currentDisplay === device.Tablet) {
            showMobileCurrentLocation();
        }

        if (!_searchInput.value) {
            // no value in search, therefore clear typeahead
            searchResults.clearSearchSuggestions();
        }
    }

    function searchInputOnLoseFocus() {
        const currentDisplay = deviceListener.getDisplay();

        if (currentDisplay === device.Mobile || currentDisplay === device.Tablet) {

            if (!this._tabOccurred) {
                hideMobileCurrentLocation();
            }
        }

        this._tabOccurred = false;
    }

    function clearSearch(clear) {
        clearSuggestionTimeout();
        const emptyString = '';

        if (clear.searchCriteria) {
            _searchInput.value = emptyString;
            _state.addSearchTerm();
        }

        if (clear.resetView) {
            _options.map.resetView();
        }

        if (clear.searchLayer) {
            _options.map.clearSearchLayer();
        }

        if (clear.results) {
            _searchResults.clearSearchResults();
        }

        _state.addSearchType();
    }

    function onCurrentPositionClick() {
        clearSearch({
            searchCriteria: true,
            results: true
        });
        hideMobileCurrentLocation();
        _state.addSearchType(searchTypes.location);

        if (!navigator.geolocation) {
            error();
            return;
        }

        navigator.geolocation.getCurrentPosition(function (position) {
            mapUtils.isWithinWales(position.coords.latitude, position.coords.longitude, (withinWales) => {
                if (withinWales === responseCodes.reverseLookupResult.withinWales) {
                    clearSearch({
                        searchCriteria: true,
                        results: true
                    });
                    _searchResults.displayResult({
                        actionText: _options.resource.SearchResultsCurrentLocationActionMessage,
                        actionLink: _options.resource.SearchResultsActionLink.replace('{0}', _options.map.getLiveMessageCount())
                    });

                    _options.map.centreOnPoint({
                        point: {
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                            radius: 10000
                        }
                    });
                } else {
                    _searchResults.displayResult({
                        error: _options.resource.SearchedGpsInvalid
                    });
                }
            });
        }, error);
    }

    function showMobileCurrentLocation() {
        document.querySelector(`.${attributes.css.class.searchMobileLocationContainer}`).classList.remove(attributes.css.class.searchMobileLocationContainerOff);
    }

    function hideMobileCurrentLocation() {
        document.querySelector(`.${attributes.css.class.searchMobileLocationContainer}`).classList.add(attributes.css.class.searchMobileLocationContainerOff);
    }

    function toggleIsSearching(searching) {
        if (searching) {
            _searchInput.classList.add(attributes.css.class.searchIsLoading);
            return;
        }

        _searchInput.classList.remove(attributes.css.class.searchIsLoading);
    }

    function onSearchClick(event) {
        event.preventDefault();

        const searchTerm = _searchInput.value;

        search(searchTerm);

        if (!searchTerm) {
            _state.addSearchTerm();
            searchResults.displayResult({
                error: _options.resource.NoResults
            });
        }
    }

    function populateTerm(searchTerm) {
        _searchInput.value = searchTerm;
    }

    function search(searchTerm) {
        if (!searchTerm) return;

        _searchInput.value = searchTerm;
        searchTerm = searchTerm.toLowerCase();

        toggleIsSearching(true);

        clearSearch({
            searchCriteria: false,
            results: true
        });
        searchResults.hideSearchSuggestions();

        let searchDataSets = [];

        if (isPostcodeSearch(searchTerm)) {            
            searchDataSets.push(postcodeSearch.doSearch(searchTerm, _options.map));
        }

        if (isNGRSearch(searchTerm)) {
            searchDataSets.push(ngrSearch.doSearch(searchTerm, _options.map));
        }

        if (searchDataSets && searchDataSets.length > 0) {
            Promise.all(searchDataSets).then(searchGroups => {
                searchResults.populateListAndShowResults({
                    error: _options.resource.NoExactMatch.replace('{0}', ''),
                    errorLinkText: _options.resource.SearchedClearHintLink,
                    searchTerm: searchTerm
                }, searchGroups);

                toggleIsSearching(false);
            });
            return;
        }

        doLocalitySearch(searchTerm, searchGroups => {
            searchResults.populateListAndShowResults({
                error: _options.resource.NoExactMatch.replace('{0}', ''),
                errorLinkText: _options.resource.SearchedClearHintLink,
                searchTerm: searchTerm
            }, searchGroups)
        });
    }

    function doLocalitySearch(searchTerm, handleResults) {
        let searchDataSets = [];
        searchDataSets.push(townsAndPlaces.doSearch(searchTerm, _options.resource).catch(e => console.error(e)));
        searchDataSets.push(targetAreas.doSearch(searchTerm, _options.resource));
        searchDataSets.push(rivers.doSearch(searchTerm, _options.resource).catch(e => console.error(e)));

        // polygon based searching - need to pass state to them so we can record details of it
        searchDataSets.push(operationalRegions.doSearch(searchTerm, _options.resource, _state).catch(e => console.error(e)));
        searchDataSets.push(policeAuthorities.doSearch(searchTerm, _options.resource, _state).catch(e => console.error(e)));
        searchDataSets.push(localAuthorities.doSearch(searchTerm, _options.resource, _state).catch(e => console.error(e)));
        searchDataSets.push(nrwDutyRoleAreas.doSearch(searchTerm, _options.resource, _state).catch(e => console.error(e)));
        searchDataSets.push(fireAuthorities.doSearch(searchTerm, _options.resource, _state).catch(e => console.error(e)));

        Promise.all(searchDataSets).then(searchGroups => {

            if (handleResults) handleResults(searchGroups);            

            toggleIsSearching(false);
        });
    }

    function isNGRSearch(searchTerm) {
        return mapUtils.isEastingNorthing(searchTerm) || mapUtils.isGridReference(searchTerm);
    }

    function isPostcodeSearch(searchTerm) {
        const postcodeMatch = searchTerm.match(RegExp(regex.search.postcode));

        return postcodeMatch;
    }

    function error(e) {
        toggleIsSearching(false);

        const error = _options.resource.SearchedGpsError;

        _searchResults.displayResult({
            error: error
        });

        if (telemetry.logError) {
            telemetry.logError({
                name: "Geolocation error",
                level: "Low",
                message: error,
                htmlMessage: error,
                toString: function () { return this.name + ": " + this.message; }
            }, error);
        }
    }

    function updateResetLinkTotalResults(total) {
        if (!_searchResults.searchResultsActionContainer.classList.contains(attributes.css.class.displayNone)) {
            _searchResults.displayResult({
                actionText: _options.resource.SearchResultsCurrentLocationActionMessage,
                actionLink: _options.resource.SearchResultsActionLink.replace('{0}', total)
            });
        }
    }

    return {
        init: init,
        search: search,
        populateTerm: populateTerm,
        clearSearch: clearSearch,
        updateResetLinkTotalResults: updateResetLinkTotalResults,
        searchCurrentLocation: onCurrentPositionClick
    }
})();

export default search;
