/*
* * Leaflet Gesture Handling **
* * A heavily modified version of https://github.com/elmarquis/Leaflet.GestureHandling
*/
import { keyCodes } from "../../constants/keyCodes";
import attributes from "../../constants/attributes";
import { Resource } from "../../Util/resource";

L.Map.mergeOptions({
    gestureHandlingOptions: {
        text: {},
        duration: 1000
    }
});

var draggingMap = false;

export var GestureHandling = L.Handler.extend({
    addHooks: function () {
        this._handleTouch = this._handleTouch.bind(this);

        this._setWarningText();
        this._disableInteractions(false);
        this._map.scrollWheelZoom.disable();

        //Uses native event listeners instead of L.DomEvent due to issues with Android touch events
        //turning into pointer events
        this._map._container.addEventListener(attributes.events.touchstart, this._handleTouch);
        this._map._container.addEventListener(attributes.events.touchmove, this._handleTouch);
        this._map._container.addEventListener(attributes.events.touchend, this._handleTouch);
        this._map._container.addEventListener(attributes.events.touchcancel, this._handleTouch);
        this._map._container.addEventListener(attributes.events.click, this._handleTouch);

        L.DomEvent.on(this._map._container, attributes.events.mousewheel, this._handleScroll, this);
        L.DomEvent.on(this._map, attributes.events.mouseover, this._handleMouseOver, this);
        L.DomEvent.on(this._map, attributes.events.mouseout, this._handleMouseOut, this);

        // Listen to these events so will not disable dragging if the user moves the mouse out the boundary of the map container whilst actively dragging the map.
        L.DomEvent.on(this._map, attributes.events.movestart, this._handleDragging, this);
        L.DomEvent.on(this._map, attributes.events.move, this._handleDragging, this);
        L.DomEvent.on(this._map, attributes.events.moveend, this._handleDragging, this);

        // Listen globally for ctrl/meta key presses (intermediary function used to preserve context of this keyword)
        document.addEventListener(attributes.events.keyup, e => {
            this._handleKeyUp(e);
        });
        document.addEventListener(attributes.events.keydown, e => {
            this._handleKeyDown(e);
        });
    },

    removeHooks: function () {
        this._enableInteractions(false);

        this._map._container.removeEventListener(attributes.events.touchstart, this._handleTouch);
        this._map._container.removeEventListener(attributes.events.touchmove, this._handleTouch);
        this._map._container.removeEventListener(attributes.events.touchend, this._handleTouch);
        this._map._container.removeEventListener(attributes.events.touchcancel, this._handleTouch);
        this._map._container.removeEventListener(attributes.events.click, this._handleTouch);

        L.DomEvent.off(this._map._container, attributes.events.mousewheel, this._handleScroll, this);
        L.DomEvent.off(this._map, attributes.events.mouseover, this._handleMouseOver, this);
        L.DomEvent.off(this._map, attributes.events.mouseout, this._handleMouseOut, this);

        L.DomEvent.off(this._map, attributes.events.movestart, this._handleDragging, this);
        L.DomEvent.off(this._map, attributes.events.move, this._handleDragging, this);
        L.DomEvent.off(this._map, attributes.events.moveend, this._handleDragging, this);

        document.removeEventListener(attributes.events.keyup, e => {
            this._handleKeyUp(e);
        });
        document.removeEventListener(attributes.events.keydown, e => {
            this._handleKeyDown(e);
        });
    },

    _handleDragging: function (e) {
        if (e.type === attributes.events.movestart || e.type === attributes.events.move) {
            draggingMap = true;
        } else if (e.type === attributes.events.moveend) {
            draggingMap = false;
        }
    },

    _disableInteractions: function (isTouchEvent) {
        if (isTouchEvent) {
            this._map.dragging.disable();
        }

        if (this._map.tap) {
            this._map.tap.disable();
        }
    },

    _enableInteractions: function (isTouchEvent) {
        if (isTouchEvent) {
            this._map.dragging.enable();
        }

        if (this._map.tap) {
            this._map.tap.enable();
        }
    },

    _setWarningText: function () {

        // Check if user is on a mac, so that meta key can be displayed instead of Ctrl
        let mac = false;
        if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
            mac = true;
        }

        let scrollContent = Resource.GestureScroll;
        if (mac) {
            scrollContent = Resource.GestureScrollMac;
        }

        // Set the content attributes on the map
        this._map._container.setAttribute("data-gesture-handling-touch-content", Resource.GestureTouch);
        this._map._container.setAttribute("data-gesture-handling-scroll-content", scrollContent);
    },

    _handleTouch: function (e) {
        //Prevent map panning on certain elements
        const ignoreList = [
            "leaflet-interactive",
            "leaflet-control-minimap",
            "leaflet-popup-content",
            "leaflet-popup-content-wrapper",
            "leaflet-popup-close-button",
            "leaflet-control-zoom-in",
            "leaflet-control-zoom-out",
            "easy-button-button",
            "easy-button-icon"
        ];

        // Check to see if an element that should be ignored has been touched
        let ignoreElement = false;
        for (let i = 0; i < ignoreList.length; i++) {
            if (L.DomUtil.hasClass(e.target, ignoreList[i])) {
                ignoreElement = true;
                break;
            }
        }

        if (ignoreElement) {
            // If the element is marked as interactive, still show the touch warning, otherwise hide the warning
            if (L.DomUtil.hasClass(e.target, "leaflet-interactive") && e.type === attributes.events.touchmove && e.touches.length === 1) {
                L.DomUtil.addClass(this._map._container, "leaflet-gesture-handling-touch-warning");
                this._disableInteractions(true);
            } else {
                L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-touch-warning");
            }
            return;
        }

        // If the event isn't a touchmove (finger being dragged across the screen) event, hide warning and return
        if (e.type !== attributes.events.touchmove) {
            L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-touch-warning");
            return;
        }

        // If it is a touch move event, and more than one finger is on the screen, then hide the warning and allow panning/zooming
        if (e.touches.length === 1) {
            L.DomUtil.addClass(this._map._container, "leaflet-gesture-handling-touch-warning");
            this._disableInteractions(true);
        } else {
            this._enableInteractions(true);
            L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-touch-warning");
        }
    },

    _warningTimeout: null,

    _warningCallback: function () {
        // Hide the warning(s)
        const warnings = document.getElementsByClassName("leaflet-gesture-handling-scroll-warning");
        for (let i = 0; i < warnings.length; i++) {
            L.DomUtil.removeClass(warnings[i], "leaflet-gesture-handling-scroll-warning");
        }
    },

    _handleScroll: function () {
        // Show/hide the scroll warning based on the scroll state
        if (this._map.scrollWheelZoom.enabled()) {
            L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-scroll-warning");
        } else {
            L.DomUtil.addClass(this._map._container, "leaflet-gesture-handling-scroll-warning");

            clearTimeout(this._warningTimeout);

            // Set a timeout to run after scrolling ends
            this._warningTimeout = setTimeout(this._warningCallback, this._map.options.gestureHandlingOptions.duration);
        }
    },

    _handleMouseOver: function (e) {
        this._enableInteractions(false);
    },

    _handleMouseOut: function (e) {
        if (!draggingMap) {
            this._disableInteractions(false);
        }
    },

    _enableScrollKeyCodes: [
        keyCodes.control,   // Control key
        keyCodes.meta       // Mac meta key
    ],

    _handleKeyDown: function (e) {
        if (!this._map.scrollWheelZoom.enabled() && this._enableScrollKeyCodes.includes(e.keyCode)) {
            this._map.scrollWheelZoom.enable();
        }
    },

    _handleKeyUp: function (e) {
        if (this._map.scrollWheelZoom.enabled() && this._enableScrollKeyCodes.includes(e.keyCode)) {
            this._map.scrollWheelZoom.disable();
        }
    }
});

L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

export default GestureHandling;
