﻿
export const device = {
    Desktop: 1,
    Tablet: 2,
    Mobile: 3
}

export const deviceListener = {

    display: null,

    mobileListener: null,
    tabletListener: null,
    desktopListener: null,

    init: function () {
        this.mobileListener = window.matchMedia("screen and (max-width: 599px)");
        this.tabletListener = window.matchMedia("screen and (min-width: 600px) and (max-width: 919px)");
        this.desktopListener = window.matchMedia("screen and (min-width: 920px)");

        this.mobileListener.addListener(this.onMobileChanged);
        this.tabletListener.addListener(this.onTabletChanged);
        this.desktopListener.addListener(this.onDesktopChanged);

        this.getDisplay();
    },

    getDisplay: function() {
        if (!this.mobileListener || !this.tabletListener || !this.desktopListener) {
            return;
        }

        if (this.mobileListener.matches) {
            this.display = device.Mobile;
        } else if (this.tabletListener.matches) {
            this.display = device.Tablet;
        } else if (this.desktopListener.matches) {
            this.display = device.Desktop;
        }

        return this.display;
    },

    onMobileChanged: function(mediaQueryList) {
        if (mediaQueryList.matches) {
            this.display = device.Mobile;
        }
    },

    onTabletChanged: function(mediaQueryList) {
        if (mediaQueryList.matches) {
            this.display = device.Tablet;
        }
    },

    onDesktopChanged: function(mediaQueryList) {
        if (mediaQueryList.matches) {
            this.display = device.Desktop;
        }
    }
};
