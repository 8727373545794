import constants from "./../constants/mapConstants";
import list from '../components/list'; 
import pagination from '../components/pagination'; 
import dataStorage from '../persistence/data-storage';
import { keyCodes } from '../constants/keyCodes';
import attributes from '../constants/attributes';
import { Resource } from '../Util/resource';

const Page = {
    _searchInput: null,
    _isEnglish: true,
    _searchResultsSection: null,
    _searchResultsContainer: null,
    _searchResultsMessage: null,
    _liveMessageListStats: null,

    build: function () {
        list.init(pagination);
        this.setFilteredList(dataStorage.getAllMessages());

        this._searchInput = document.querySelector(attributes.css.id.searchInput);
        this._searchResultsSection = document.querySelector(`.${attributes.css.class.searchResultsSection}`);
        this._searchResultsContainer = document.querySelector(`.${attributes.css.class.searchResultsContainer}`);
        this._searchResultsMessage = document.querySelector(`.${attributes.css.class.searchResultsMessage}`);
        this._liveMessageListStats = document.querySelector(`.${attributes.css.class.liveMessageListStats}`);

        document.querySelector(attributes.css.id.searchButton).addEventListener(attributes.events.click, this.performSearch.bind(this));
        document.querySelector(attributes.css.id.searchInput).addEventListener(attributes.events.keyup, this.searchInputKeyup.bind(this));
    },

    searchInputKeyup: function(e) {
        const keycode = e.which || e.keycode;

        if (keycode === keyCodes.return) {
            this.performSearch();
        }
    },

    performSearch: function() {
        var searchValue = this._searchInput.value;
        var items = dataStorage.getAllMessages();
        this.toggleErrorMessage(false);

        var _this = this;
        var filteredItems = items.filter(i => _this.matchItem(i.name, searchValue) || _this.matchItem(i.description, searchValue));

        if (!searchValue) {
            filteredItems = [];
        }

        const a = document.createElement('button');
        a.setAttribute('role', 'link');
        a.innerHTML = Resource.SearchedClearHintLink;
        a.classList.add('search-results__clear');
        a.setAttribute('tabindex', '0');
        a.addEventListener(attributes.events.click, this.clearSearch.bind(this, true));

        if (filteredItems.length === 0) {
            
            this._searchResultsMessage.innerText = `${Resource.SearchNoResults} `;
            this._searchResultsMessage.appendChild(a);
            this.toggleErrorMessage(true);
        } else {
            this._liveMessageListStats.appendChild(a);            
        }

        this.setFilteredList(filteredItems, true);
    },

    clearSearch() {
        this._searchInput.value = '';
        this.toggleErrorMessage(false);
        this.setFilteredList(dataStorage.getAllMessages());
    },

    matchItem: function(localisedString, value) {
        value = value.toLowerCase();
        var itemValue = this._isEnglish ? localisedString.english : localisedString.welsh;

        if (itemValue) {
            return itemValue.toLowerCase().indexOf(value) > -1;
        }

        return false;
    },

    toggleErrorMessage: function(display) {
        if (display) {
            this._searchResultsContainer.classList.remove(attributes.css.class.displayNone);
            this._searchResultsSection.classList.remove(attributes.css.class.displayNone);
            this._liveMessageListStats.classList.add(attributes.css.class.displayNone);
        } else {
            list.resetDefaultMessage();
            this._searchResultsMessage.innerHTML = '';
            this._searchResultsContainer.classList.add(attributes.css.class.displayNone);
            this._searchResultsSection.classList.add(attributes.css.class.displayNone);
            this._liveMessageListStats.classList.remove(attributes.css.class.displayNone);
        }
    },

    setFilteredList: function(filteredList, usePartial) {
        list.populateList(filteredList, usePartial);
    },

    setData: function (liveMessages, culture) {
        this._isEnglish = culture === attributes.culture.english;

        liveMessages.messages.filter(m => m.severity > 0).forEach(liveMessage => {
            liveMessage.iconUrl = constants.severityIconRenderer[liveMessage.severity].symbol.options.iconUrl;
        })

        liveMessages.messages.sort((a, b) => {
            if (this._isEnglish) {
                return a.name.english > b.name.english ? 1 : -1;
            }
            else {
                return a.name.welsh > b.name.welsh ? 1 : -1;
            }
        });
        dataStorage.setAllMessages(liveMessages);
    },
};

export { Page };
