﻿import telemetry from '../telemetry/application-insights';
import attributes from '../constants/attributes';
import dataStorage from '../persistence/data-storage';
import moment from 'moment';

const refresh = (function () {

    let _lastUpdated = null;
    let _callback = null;

    function init(callback) {
        document.querySelectorAll(`.${attributes.css.class.refreshLink}`).forEach((value, key, parent) => {
            value.addEventListener(attributes.events.click, refreshPage);
        });

        _lastUpdated = document.querySelectorAll(`.${attributes.css.class.lastUpdatedTimestamp}`);
        _callback = callback;
    }

    function refreshPage(event) {

        event.preventDefault();

        var lastUpdates = dataStorage.getLastUpdated();

        $.post(`/api/refresh`, lastUpdates).done((data) => {

            if (!data) {
                if (telemetry.logError) telemetry.logError(null, 'No data returned for manual refresh');
                Object.defineProperty(window, 'location', {
                    value: '/error/500'
                }); // had to use this method rather than window.location otherwise the unit tests fail
                return;
            }

            if (_callback) {
                _callback(data.updateRequired);
            }

            setLastRefreshTimestamp(data.lastUpdated);

        }).fail((error) => {
            if (telemetry.logError) telemetry.logError(error, 'Failed to perform a manual page refresh');

            if (error.status === 503) {
                window.location.reload(true);
            } else {
                Object.defineProperty(window, 'location', {
                    value: '/error/500'
                });
            }
        });
    }

    function setLastRefreshTimestamp(lastUpdated) {
        var lastRefreshTimestamp = moment(lastUpdated).format('HH:mm D MMMM YYYY');
        _lastUpdated.forEach((value, key, parent) => {
            value.innerText = lastRefreshTimestamp;
        });
    }

    return {
        init: init,
    }
})();

export default refresh;
