const generalUtils = {
    copyTextToClipboard: function(textToCopy) {
        const ta = document.createElement('textarea');
        ta.value = textToCopy;
        ta.readOnly = true;
        ta.contentEditable = true;
        
        ta.style.position = 'fixed';
        ta.style.left = '0';
        ta.style.right = '0';
        ta.style.top = '15vh';
        ta.style.bottom = '0';
        ta.style.height = '1px';
        ta.style.width = '1px';
        ta.style.zIndex = '0';
        ta.style.margin = '0 auto';
        document.body.appendChild(ta);
        
        ta.select();
        
        const range = document.createRange();
        range.selectNodeContents(ta);
        
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        
        ta.setSelectionRange(0, 999999);
        document.execCommand('copy');
        document.body.removeChild(ta);
    }
}

export default generalUtils;
