﻿import * as cluster from '../../Extensions/MarkerClusterGroup/index';
import constants from './../../constants/mapConstants';
import templates from "../../constants/templates";

const clusterGroup = {

    addHoverHighlightCssClass: false,

    init: function (clusteringDisabled) {

        //account for clustering library not being available
        let layerGroup = cluster.MarkerClusterGroup && !clusteringDisabled ? this.initClustering() : new L.LayerGroup();
        
        return layerGroup;
    },

    initClustering: function () {
        return new cluster.MarkerClusterGroup({
            showCoverageOnHover: false,
            maxClusterRadius: zoom => {
                if (zoom < 12) return 50;

                return 0;
            },
            iconCreateFunction: this.iconCreateFunction,
            select: this.select,
            unselect: this.unselect
        });
    },

    iconCreateFunction: function (cluster) {
        //todo: ignore sev 4's  from this list
        const getSeverity = clusterLayer => {
            let severity = 4;
            let childSeverity = 4;

            if (clusterLayer._childClusters.length > 0) {


                clusterLayer._childClusters.forEach(childCluster => {
                    let newSeverity = getSeverity(childCluster);
                    if (severity > newSeverity) severity = newSeverity;
                });

                childSeverity = severity;
            }

            if (clusterLayer._markers.length > 0) {
                const sortedList = clusterLayer._markers.sort((a, b) => a.severity - b.severity);
                return sortedList[0].severity < childSeverity ? sortedList[0].severity : childSeverity;
            }

            return childSeverity;
        };

        const severity = getSeverity(cluster);

        var clusterIconUrl = constants.severityIconRenderer[severity].clusterIconUrl;

        const selectedCssClass = this.addHoverHighlightCssClass ? constants.symbol.selectedCssClass : '';
        let template = templates.getClusterIconTemplate(clusterIconUrl, cluster.getChildCount(), selectedCssClass);

        return L.divIcon({
            html: template,
            iconSize: L.point(40, 40)
        });
    },

    select: function () {
        this.addHoverHighlightCssClass = true;
    },

    unselect: function () {
        this.addHoverHighlightCssClass = false;
    }
}

export default clusterGroup;
