import responseCodes from '../../constants/responseCodes';

const postcodeSearch = {
    doSearch: function (keyword, map) {
        const uri = `/api/search/postcode/${keyword}`;

        return new Promise((resolve, reject) => {
            $.get(uri)
            .done((data) => {
                resolve(this._parseResults(data, map));
            }).fail((error) => {
                if (error.status !== responseCodes.httpResponseCodes.badRequest) {
                    let errorMessage = 'Postcode not within Wales';
                    if (appInsights.logError) appInsights.logError(null, errorMessage);
                    reject(errorMessage);
                    return;
                }
                
                // otherwise, the postcode is outside of wales (BadRequest), therefore just call
                // parseReults with nothing to parse
                resolve(this._parseResults());
            });
        });
    },

    _parseResults: function (data, map) {
        let results = [];

        if (data) {
            const extent = map.convertToLatLongExtent(data.xMin, data.yMin, data.xMax, data.yMax);
            results.push({
                extent: extent,
                selected: this.listItemSelected.bind(extent)
            });
        }

        return {
            id: 'postcode',
            results: results,
        };
    },

    listItemSelected: function (map, callback) {
        if (callback) callback();

        map.centreOnPoint({ extent: this });
    }
}

export default postcodeSearch;
