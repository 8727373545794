﻿import templates from "../constants/templates";
import attributes from '../constants/attributes';

const list = {

    listContainer: document.querySelector(".live-message-list__items"),
    listTitle: document.querySelector(".live-message-list__stats"),

    fullList: null,

    pagination: null,
    templateFunction: null,
    usePartial: false,

    init: function (pagination, map) {
        this.map = map;
        this.pagination = pagination;
        this.pagination.init(this);
    },

    setListOnlyMode: function () {
        //set list only mode:
        const viewContainerName = "view-container";
        document.querySelector(`.${viewContainerName}`).classList.remove(`${viewContainerName}--map-view`);
        document.querySelector(`.${viewContainerName}`).classList.remove(`${viewContainerName}--combined-view`);
        document.querySelector(`.${viewContainerName}`).classList.add(`${viewContainerName}--list-view`);
        document.querySelector(".tabs__container").classList.add("display-none");
    },

    scrollToTop: function () {
        this.listTitle.scrollIntoView();
    },

    populateList: function (listItems, usePartial) {
        this.usePartial = usePartial;
        this.fullList = listItems;
        this.listContainer.innerHTML = "";
        this.pagination.paginate();
    },

    setPaginationResultText: function (start, end, total) {
        let allResultsRequired = true;
        
        if (this.map) {
            allResultsRequired = this.map ? this.map.isMaxZoomLevel() : true;
        } else if (this.usePartial) {
            allResultsRequired = false;
        }
        
        const resultTemplate = JSON.parse(this.listTitle.dataset.text);
        let messageType = allResultsRequired ? resultTemplate.all : resultTemplate.partial;
        let text = messageType.empty;

        if (total > 0) {
            text = (messageType.ranged)
                .replace('{start}', start)
                .replace('{end}', end < total ? end : total)
                .replace('{total}', total);
        }

        if (this.listTitle.childNodes && this.listTitle.childNodes.length > 0) {
            this.listTitle.childNodes[0].nodeValue = text;
        } else {
            this.listTitle.innerHTML = text;
        }

    },

    resetDefaultMessage: function() {
        const resultTemplate = JSON.parse(this.listTitle.dataset.text);
        this.listTitle.childNodes[0].nodeValue = resultTemplate.all;

        if (this.listTitle.childNodes.length > 1) {
            this.listTitle.childNodes[1].remove();
        }
    },

    addListItems: function (listItems) {
        listItems.forEach(listItem => {
            this.addListItem(listItem);
        });
    },

    addListItem: function (listItem) {
        let icon = "";
        if (listItem && listItem.iconUrl) icon = `<img src="${listItem.iconUrl}" alt="" aria-hidden="true" class="live-message__severity-icon" />`;

        let classes = "";
        if (listItem.active) {
            classes += " live-message--active";
        }
        if (listItem.severityCssModifier) {
            classes += " live-message--" + listItem.severityCssModifier;
        }

        //todo: list item name = .GetByCulture(RequestCultureProvider.GetRequestCulture())
        //todo: date formats = .ToString("HH:mm d MMM yyyy")
        //todo: culture language for labels
        
        var itemContainer = document.createElement("div");
        itemContainer.innerHTML = templates.getListItemTemplate(listItem, classes, icon);
        this.listContainer.appendChild(itemContainer);
        
        this.addListItemOnHoverEventListner(itemContainer, listItem);
    },

    addListItemOnHoverEventListner: function (itemContainer, listItem) {

        itemContainer.addEventListener(attributes.events.mouseover, event => {

            if (listItem.isHighlighted) {
                return;
            }

            if (listItem.graphic) listItem.graphic.select(true);

            if (listItem.cluster instanceof L.MarkerClusterGroup) {
                listItem.cluster.options.select();
                listItem.cluster.refreshClusters(listItem.graphic);
            }

            listItem.isHighlighted = true;
        });

        itemContainer.addEventListener(attributes.events.mouseleave, event => {

            if (!listItem.isHighlighted) {
                return;
            }

            if (listItem.graphic) listItem.graphic.unselect();

            if (listItem.cluster instanceof L.MarkerClusterGroup) {
                listItem.cluster.options.unselect();
                listItem.cluster.refreshClusters(listItem.graphic);
            }

            listItem.isHighlighted = false;
        });
    }
};

export default list;
