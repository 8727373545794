﻿const targetAreas = {

    map: null,

    init: function (map) {
        this.map = map;
    },

    doSearch: function (keyword, resource) {
        let list = this.map.getFilteredList(keyword);
        if (!list) return {
            results: [],
        };

        const totalCount = list.length;
        if (list.length > 4) list = list.splice(0, 4);
        list = list.map(x => {
            x.selected = this.itemSelected.bind(x);
            return x;
        });

        let showTotalMessage = '';
        if (list.length < totalCount) {
            showTotalMessage = resource.SearchResultsCategoryListStats.replace('{start}', list.length).replace('{total}', totalCount);
        }

        return {
            id: "targetAreas",
            title: resource.SearchCategoryTargetAreaNames,
            results: list,
            showTotalMessage: showTotalMessage
        };
    },

    itemSelected: function (map, callback) {
        if (callback) callback();
        this.fitToBounds();        
    }

}

export default targetAreas;
