﻿const languageUtility = {

    language: document.documentElement.lang || "en",

    text: function (localisedString) {
        if (!localisedString) {
            return localisedString;
        }

        if (this.language === "en") {
            let text = localisedString.english;
            if (text && typeof text === "string") {
                return text;
            }
            return localisedString.welsh;
        }

        if (this.language === "cy") {
            let text = localisedString.welsh;
            if (text && typeof text === "string") {
                return text;
            }
            return localisedString.english;
        }

        throw new Error(`unknown language ${this.language}`);
    }
}

export default languageUtility;
