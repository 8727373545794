﻿import templates from '../constants/templates';
import attributes from '../constants/attributes';
import language from '../Util/language';

const searchResults = {

    searchInput: document.querySelector(attributes.css.class.searchInput),

    // Search Results Contianers
    searchResultsSection: document.querySelector(`.${attributes.css.class.searchResultsSection}`),
    searchResultsContainer: document.querySelector(`.${attributes.css.class.searchResultsSection} .${attributes.css.class.searchResultsContainer}`),
    searchResultsActionContainer: document.querySelector(`.${attributes.css.class.searchResultsSection} .${attributes.css.class.searchResultsActionContainer}`),

    // Search Results Action & Error Messages        
    searchResultsMessage: document.querySelector(`.${attributes.css.class.searchResultsSection} .${attributes.css.class.searchResultsMessage}`),
    searchResultsError: document.querySelector(`.${attributes.css.class.searchResultsSection} .${attributes.css.class.searchResultsError}`),
    searchResultsActionText: document.querySelector(`.${attributes.css.class.searchResultsSection} .${attributes.css.class.searchResultsActionText}`),
    searchResultsActionLink: document.querySelector(`.${attributes.css.class.searchResultsSection} .${attributes.css.class.searchResultsActionLink}`),

    searchResultsAutoCompleteContainer: document.querySelector(`.${attributes.css.class.searchAutoComplete}`),
    searchResultsAutoCompleteResultsContainer: document.querySelector(`.${attributes.css.class.searchAutoComplete} .${attributes.css.class.searchResults}`),

    clearSearchAction: null,
    map: null,
    resource: null,
    state: null,

    init: function (clearSearchAction, map, state, resource) {
        this.resource = resource;
        this.clearSearchAction = clearSearchAction;
        this.map = map;
        this.state = state;
        this.searchResultsActionLink.addEventListener(attributes.events.click, this.clearSearch.bind(this, true));

        //if clicking outside of the suggestions then hide it
        document.addEventListener('click', (e) => {

            const target = e.target || e.srcElement;
            if (target.classList.contains('search-results__result') || target.classList.contains('search-results__title')) {
                return;
            }

            if (target.classList.contains('search__input')) {
                this.showSearchSuggestions();
                return;
            }

            this.hideSearchSuggestions();
        });
    },

    clearSearch: function (clearView) {
        this.clearSearchAction({
            searchCriteria: true,
            resetView: clearView,
            searchLayer: true
        })
        this.clearSearchResults();
    },

    populateSearchSuggestions: function (searchResultGroups, searchTerm) {
        this.searchResultsAutoCompleteContainer.classList.remove(attributes.css.class.displayNone);

        const groupsWithResults = searchResultGroups.filter(x => x && x.results && x.results.length > 0);
        if (groupsWithResults.length === 0) {
            this.clearSearchSuggestions();
            return;
        }

        this._buildResults(this.searchResultsAutoCompleteResultsContainer, groupsWithResults, searchTerm);
    },

    populateListAndShowResults: function (options, searchResultGroups) {
        this.searchResultsSection.classList.remove(attributes.css.class.displayNone);
        this.searchResultsContainer.classList.remove(attributes.css.class.displayNone);
        const resultsContainer = this.searchResultsContainer;

        this.state.addSearchTerm(options.searchTerm);

        const groupsWithResults = searchResultGroups.filter(x => x && x.results && x.results.length > 0);
        if (groupsWithResults.length === 0) {
            this.displayResult({
                error: this.resource.NoResults
            });
            return;
        }

        if (groupsWithResults.length === 1 && groupsWithResults[0].results.length === 1) {
            //only one result, don't show the list just action its "selected" method
            const result = groupsWithResults[0].results[0];
            result.selected(this.map, this.resultSelected.bind(this));
            return;
        }

        let totalResults = 0;
        groupsWithResults.forEach(x => { if (x.id !== 'townsPlaces') totalResults += x.results.length });

        this._buildResults(resultsContainer, groupsWithResults, options.searchTerm);

        if (totalResults > 4) {
            options.error = this.resource.TooManyResults;
        }

        this.displayResult({
            actionText: options.actionText,
            actionLink: options.actionLink,
            error: options.error
        });

        if (options.errorLinkText) {
            this.addErrorLink(options.errorLinkText);
        }
    },

    _buildResults: function (resultsContainer, groupsWithResults, searchTerm) {
        resultsContainer.innerHTML = "";

        groupsWithResults.forEach(group => {
            this.addGroupToResults(group, searchTerm, resultsContainer);
        });        
    },

    addErrorLink: function (errorLinkText) {
        const a = document.createElement('button');
        a.setAttribute('role', 'link');
        a.innerHTML = errorLinkText;
        a.classList.add('search-results__clear');
        a.setAttribute('tabindex', '0');
        this.searchResultsError.appendChild(a);
        a.addEventListener(attributes.events.click, this.clearSearch.bind(this, true));
    },
    
    displayResult: function (options) {
        const hasMsg = options.msg && options.msg.length > 0;
        const hasError = options.error && options.error.length > 0;
        const hasAction = options.actionText && options.actionLink && options.actionText.length > 0 && options.actionLink.length > 0;

        if (!hasMsg && !hasError && !hasAction) {
            return;
        }

        this._showHideElement(true, this.searchResultsSection);

        if (hasMsg) {
            this.searchResultsMessage.innerHTML = options.msg;
            this._showHideElement(true, this.searchResultsContainer);
        }

        if (hasError) {
            this.searchResultsError.innerHTML = options.error;
            this._showHideElement(true, this.searchResultsContainer);
            this._showHideElement(true, this.searchResultsError);
            this.searchResultsError.focus();
        }

        if (hasAction) {
            this.searchResultsActionText.innerHTML = options.actionText;
            this.searchResultsActionLink.innerHTML = options.actionLink;
            this._showHideElement(true, this.searchResultsActionContainer);
            this.searchResultsActionContainer.focus();
        }
    },

    addGroupToResults: function (group, searchTerm, resultsContainer) {
        let searchResultGroupComponents = templates.getSearchResultsListGroupTemplate(group);
        let searchResultsGroupContainer = searchResultGroupComponents.searchResultsGroupContainer;
        resultsContainer.appendChild(searchResultsGroupContainer);

        const groupContainer = searchResultGroupComponents.listDiv; 

        group.results.forEach(result => {
            groupContainer.appendChild(this.addSearchResult(result, searchTerm));
        });
    },

    addSearchResult: function (result, searchTerm) {
        let text = language.text(result.text);
        
        let a = document.createElement('button');
        a.classList.add('search-results__result');
        a.setAttribute('role', 'option');
        a.setAttribute('aria-label', language.text(result.text));
        a.setAttribute('tabindex', 0);
        a.addEventListener(attributes.events.click, this.listItemSelected.bind(this, result, text));
        
        //get the matching text on the search term and make it bold
        const regex = new RegExp(searchTerm, 'i');
        if (regex.test(text)) {
            const index = regex.exec(text).index;
            const substring = text.substring(index, index + searchTerm.length);
            text = text.replace(substring, `<b>${substring}</b>`);
        }

        a.innerHTML = text;

        return a;
    },

    listItemSelected: function (listItem, text, e) {
        listItem.selected(this.map, this.resultSelected.bind(this));
        this.searchInput.value = text;
        this.searchResultsAutoCompleteResultsContainer.innerHTML = '';
        this.state.addSearchTerm(text, true);

        e.preventDefault();
    },

    resultSelected: function () {
        this.clearSearchAction({
            searchCriteria: false,
            resetView: false,
            results: true,
            searchLayer: true
        });

        this.displayResult({
            actionText: this.resource.SearchResultsActionMessage,
            actionLink: this.resource.SearchResultsActionLink.replace('{0}', this.map.getLiveMessageCount())
        });
    },

    clearSearchResults: function () {
        const emptyString = '';

        this.searchResultsMessage.innerHTML = emptyString;
        this.searchResultsError.innerHTML = emptyString;
        this.searchResultsActionText.innerHTML = emptyString;
        this.searchResultsActionLink.innerHTML = emptyString;
        this.searchResultsContainer.innerHTML = emptyString;
    
        this._showHideElement(false, this.searchResultsSection);
        this._showHideElement(false, this.searchResultsContainer);
        this._showHideElement(false, this.searchResultsActionContainer);

        this.hideSearchSuggestions();
    },

    showSearchSuggestions: function() {
        // only show if there are results to show
        if (this.searchResultsAutoCompleteResultsContainer.innerHTML !== '') {
            this._showHideElement(true, this.searchResultsAutoCompleteContainer);
        }
    },

    clearSearchSuggestions: function() {
        this.searchResultsAutoCompleteResultsContainer.innerHTML = '';
    },

    hideSearchSuggestions: function() {
        this._showHideElement(false, this.searchResultsAutoCompleteContainer);
    },

    _showHideElement: function (show, element) {
        const classRenameSuffix = '-x';

        if (show) {
            element.classList.remove(attributes.css.class.displayNone);
            element.classList.add(attributes.css.class.displayNone + classRenameSuffix);
        } else {
            element.classList.remove(attributes.css.class.displayNone + classRenameSuffix);
            element.classList.add(attributes.css.class.displayNone);
        }
    },

};

export default searchResults;
