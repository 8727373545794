﻿import attributes from "../constants/attributes";
import { keyCodes } from "../constants/keyCodes";

let modal = (function(iconSelector, modalSelector, callback) {
    const classModalContainerVisibleModifier = 'modal-container--visible';
    const classModalVisibleModifier = 'modal--visible';
    const classContentHeightModifier = 'content--modal-open';
    const classModalCloseButton = '.modal__close-button';
    const classModal = 'modal';

    var _lastFocusedElement = null;
    var _modalContainer = document.querySelector('.modal-container');
    var _pageContent = document.querySelector('.content');
    var _modal = document.querySelector(modalSelector);

    const icon = document.querySelector(iconSelector);
    icon.addEventListener(attributes.events.click, open);

    const closeButton = _modal.querySelector(classModalCloseButton);
    closeButton.addEventListener(attributes.events.click, close);

    _modalContainer.addEventListener(attributes.events.click, (e) => {
        // only close the modal if we've clicked outside of it
        if (e.target.classList && (e.target.classList.contains(classModal) || e.target.closest(`.${classModal}`))) {
            return;
        }
        
        close();
    });

    document.body.addEventListener(attributes.events.keyup, function (event) {
        if (event.keyCode === keyCodes.escape) {
            close();
        }
    });

    if (callback) {
        callback(_modal);
    }

    const allElements = _modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    if (allElements.length > 0) {
        allElements[allElements.length - 1].addEventListener(attributes.events.keydown, function(e) {
            if (e.keyCode === keyCodes.tab) {
                allElements[0].focus();
                
                e.preventDefault();
            }
        });
    }

    function open(event) {
        event.preventDefault();

        _lastFocusedElement = document.activeElement;

        _modalContainer.classList.add(classModalContainerVisibleModifier);
        
        _modal.classList.add(classModalVisibleModifier);
        _modal.setAttribute(attributes.aria.disabled, attributes.global.false);
        _modal.setAttribute(attributes.aria.hidden, attributes.global.false);
        _modal.focus();

        _pageContent.setAttribute(attributes.aria.disabled, attributes.global.true);
        _pageContent.setAttribute(attributes.aria.hidden, attributes.global.true);
        _pageContent.inert = true;
    }

    function close() {
        _modalContainer.classList.remove(classModalContainerVisibleModifier);

        _pageContent.classList.remove(classContentHeightModifier);
        _pageContent.setAttribute(attributes.aria.disabled, attributes.global.false);
        _pageContent.setAttribute(attributes.aria.hidden, attributes.global.false);
        _pageContent.inert = false;

        _modal.classList.remove(classModalVisibleModifier);
        _modal.setAttribute(attributes.aria.disabled, attributes.global.true);
        _modal.setAttribute(attributes.aria.hidden, attributes.global.true);

        if (_lastFocusedElement !== null) {
            _lastFocusedElement.focus();
        }
    }

    // function being returned for ease of testing
    return {
        open: open,
        close: close
    }
});

export default modal;