﻿import attributes from "../constants/attributes";

let accordion = (function(containerSelector) {
    const titleSelector = '.accordion__title-bar';
    const contentSelector = '.accordion__content';
    const accordionClosedCssClass = 'accordion--closed';

    var _container = document.querySelector(containerSelector);
    var _title = _container.querySelector(titleSelector);
    var _content = _container.querySelector(contentSelector);

    _title.addEventListener(attributes.events.click, toggle);

    if (!_container.classList.contains(accordionClosedCssClass)) {
        open();
    }

    function open() {
        _container.classList.remove(accordionClosedCssClass);
        _content.classList.remove(attributes.css.class.displayNone);
        _title.setAttribute(attributes.aria.expanded, attributes.global.true);
    }

    function close() {
        _container.classList.add(accordionClosedCssClass);
        _content.classList.add(attributes.css.class.displayNone);
        _title.setAttribute(attributes.aria.expanded, attributes.global.false);
    }

    function toggle(event) {
        event.preventDefault();

        if (_container.classList.contains(accordionClosedCssClass)) {
            open();
        } else {
            close();
        }
    }

    // these are not actually required to be public, but they allow us to run our tests a lot easier
    return {
        open: open,
        close: close,
        toggle: toggle
    }
})

export default accordion;