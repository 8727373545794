﻿const attributes = {
    aria: {
        describedBy: 'aria-describedby',
        disabled: 'aria-disabled',
        expanded: 'aria-expanded',
        hidden: 'aria-hidden',
        labelledBy: 'aria-labelledby',
    },
    css: {
        id: {
            searchButton: '#searchSubmit',
            searchInput: '#searchInput',
            togglePolygons: '#toggle-polygons'
        },
        dom: {
            disabled: 'disabled',
        },
        class: {
            displayNone: 'display-none',
            lastUpdatedTimestamp: 'last-updated__date',
            refreshLink: 'last-updated__refresh-link',
            searchInput: 'input.search__input',
            searchLocationButton: 'search__location-button',
            searchMobileLocationContainer: 'search__mobile-location-container',
            searchMobileLocationContainerOff: 'search__mobile-location-container--off',
            searchResultsContainer: 'search-results__container',
            searchResultsError: 'search-results__text--error', 
            searchResultsMessage: 'search-results_message',
            searchResultsSection: 'search-results-section',
            searchResultsActionContainer: 'search-results__text--searched',
            searchResultsActionLink: 'search-results__clear',
            searchResultsActionText: 'search-results__searched-text',
            searchIsLoading: 'search__input--loading',
            mapIsLoading: 'map-loading--visible',
            mapLoading: 'map-loading',
            searchAutoComplete: 'search__autocomplete',
            searchResults: 'search-results',
            liveMessageListStats: 'live-message-list__stats'
        }
    },
    events: {
        blur: 'blur',
        click: 'click',
        dragend: 'dragend',
        focus: 'focus',
        keydown: 'keydown',
        keyup: 'keyup',
        load: 'load',
        mouseleave: 'mouseleave',
        mouseout: 'mouseout',
        mouseover: 'mouseover',
        mousewheel: 'mousewheel',
        mousedown: 'mousedown',
        move: 'move',
        moveend: 'moveend',
        movestart: 'movestart',
        remove: 'remove',
        touchcancel: 'touchcancel',
        touchend: 'touchend',
        touchmove: 'touchmove',
        touchstart: 'touchstart',
        zoomend: 'zoomend',
        change: 'change'
    },
    culture: {
        english: 'en-GB',
        welsh: 'en-CY'
    },
    global: {
        false: 'false',
        true: 'true'
    }
}

export default attributes;
