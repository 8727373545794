﻿import { ApplicationInsights } from '@microsoft/applicationinsights-web';

(function () {

    //only init once
    if (window.appInsights) return;

    var Microsoft = window.Microsoft || {};
    Microsoft.ApplicationInsights = {
        ApplicationInsights: ApplicationInsights
    };

    var appInsights = window.appInsights = new Microsoft.ApplicationInsights.ApplicationInsights({
        config: {
            instrumentationKey: window.__appInsightsKey
        }
    });
    delete window.__appInsightsKey;
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    window.logError = function (ex, message) {
        if (window.appInsights) {
            window.appInsights.trackException({ exception: ex, properties: { message: message } });
        }

        console.error(ex);
    };

    return appInsights;
})();

export default {
    logError: window.logError
};

