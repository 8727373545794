﻿// Add resources coming from the server here.
// Note: if this mapping contains something that the server doesn't provide, this entire file will not work.
export let Resource = {
    "FloodWarningBannerOutOfForceMobile": "",
    "FloodWarningBannerOutOfForceDesktop": "",
    "FloodWarningBannerSevereFloodWarningAccessibilityLabel": "",
    "FloodWarningBannerFloodWarningAccessibilityLabel": "",
    "FloodWarningBannerFloodAlertAccessibilityLabel": "",
    "FloodWarningBannerSevereFloodWarning": "",
    "FloodWarningBannerFloodWarning": "",
    "FloodWarningBannerFloodAlert": "",
    "Issued": "",
    "MapInteractivityCtrlScroll": "",
    "MapInteractivityTwoFingerTouch": "",
    "NoExactMatch": "",
    "NoResults": "",
    "PageNumber": "",
    "RefreshError": "",
    "RefreshLinkNonRealTime": "",
    "RefreshLinkRealTime": "",
    "RefreshNonRealTime": "",
    "RefreshingInProgress": "",
    "SearchCategoryPlaces": "",
    "SearchCategoryRivers": "",
    "SearchCategoryTargetAreaNames": "",
    "SearchedClearHint": "",
    "SearchedClearHintLink": "",
    "SearchedGps": "",
    "SearchedGpsError": "",
    "SearchedGpsInvalid": "",
    "SearchNoResults": "",
    "SearchedSpatial": "",
    "SearchedSpatialCategory": "",
    "SearchedDatasetPolygonCategoryLoaded": "",
    "SearchedDatasetPolygonCategoryLoading": "",
    "SearchedDatasetPolygonCategoryNoneInForce": "",
    "SearchResultsActionLink": "",
    "SearchResultsActionMessage": "",
    "SearchResultsCategoryListStats": "",
    "TooManyResults": "",
    "Updated": "",
    "ApiCallNotAvailable": "",
    "NoRelatedFloodAreas": "",
    "NoPreviousHistory": "",
    "StatusChangeLimitText": "",
    "ViewDetails": "",
    "GestureTouch": "",
    "GestureScroll": "",
    "GestureScrollMac": "",
    "FloodWarningBannerRemovedWarnings": "",
    "FloodWarningOutOfForce": "",
    "FloodAlertOutOfForce": "",
    "MapHome": "",
    "SearchResultsFireAuthorities": "",
    "SearchResultsLocalAuthority": "",
    "SearchResultsNRWDutyRoleAreas": "",
    "SearchResultsOperationalRegions": "",
    "SearchResultsPoliceAuthorities": "",
    "SearchResultsCurrentLocationActionMessage": "",
    "SearchNoTerm": "",
    "AtoZSearchMessage": "",
    "FirstPage": "",
    "Graph": "",
    "LastPage": "",
    "LessData": "",
    "LevelFalling": "",
    "LevelRising": "",
    "LevelSteady": "",
    "ListStatsEmpty": "",
    "ListStatsRanged": "",
    "ListStatsTotal": "",
    "MoreData": "",
    "NextPage": "",
    "Offline": "",
    "Pagination": "",
    "PreviousPage": "",
    "RainfallDry": "",
    "RainfallHeavyRain": "",
    "RainfallHelpText": "",
    "RainfallLightRain": "",
    "RainfallModerateRain": "",
    "UpToDateReadingNotAvailable": "",
    "View": "",
    "ViewGraph": "",
    "ViewLessData": "",
    "ViewMoreData": "",
    "ViewPerPage": "",
    "ViewRainfallLevelGraph": "",
    "ViewRiverLevelGraph": "",
    "ViewTidalLevelGraph": "",
    "RiverServiceUnavailable": ""
};

export function loadResources() {
    // @ts-ignore
    let pageResources = window.__resources || {};
    if (window.resource) {
        Resource = window.resource;
        return;
    }

    Object.keys(Resource).forEach(key => {
        if (!(key in pageResources)) {
            return;
        }
        Resource[key] = pageResources[key];
    });

    window.resource = Resource;
}

