﻿import templates from "../constants/templates";
import { Resource } from "../Util/resource";

const summary = {

    eventListenersRegistered: false,

    alertBannerCount: document.querySelector(".flood-warning-count--flood-alert span"),
    severeBannerCount: document.querySelector(".flood-warning-count--severe-flood-warning span"),
    warningBannerCount: document.querySelector(".flood-warning-count--flood-warning span"),

    alertDropDownToggle: document.querySelector("a.flood-warning-count--flood-alert"),
    severeDropDownToggle: document.querySelector("a.flood-warning-count--severe-flood-warning"),
    warningDropDownToggle: document.querySelector("a.flood-warning-count--flood-warning"),
    outOfForceDropDownToggle: document.querySelector("#out-of-force-warnings-dropdown-button"),
    outOfForceMobileDropDownToggle: document.querySelector("#out-of-force-warnings-mobile-dropdown-button"),

    severeDropDown: document.querySelector("#flood-warning-count--severe-flood-warning"),
    warningDropDown: document.querySelector("#flood-warning-count--flood-warning"),
    alertDropDown: document.querySelector("#flood-warning-count--flood-alert"),
    outOfForceDropDown: document.querySelector("#out-of-force-warnings-dropdown"),
    outOfForceMobileDropDown: document.querySelector("#out-of-force-warnings-mobile-dropdown"),

    severeDropDownItemsContainer: document.querySelector("#flood-warning-count--severe-flood-warning .flood-warning-count-dropdown__target-area-list"),
    warningDropDownItemsContainer: document.querySelector("#flood-warning-count--flood-warning .flood-warning-count-dropdown__target-area-list"),
    alertDropDownItemsContainer: document.querySelector("#flood-warning-count--flood-alert .flood-warning-count-dropdown__target-area-list"),
    outOfForceDropDownItemsContainer: document.querySelector("#out-of-force-warnings-dropdown .flood-warning-count-dropdown__target-area-list"),
    outOfForceMobileDropDownItemsContainer: document.querySelector("#out-of-force-warnings-mobile-dropdown .flood-warning-count-dropdown__target-area-list"),

    outOfForceWarningButtonOpenClassName: "flood-warning-count-out-of-force-desktop--open",
    outOfForceMobileWarningButtonOpenClassName: "flood-warning-count-out-of-force-mobile--open",
    dropdownOpenClassname: "flood-warning-count-dropdown--open",
    buttonOpenClassName: "flood-warning-count--open",

    bannerItemClassList: [
        'flood-warning-count',
        'flood-warning-count__text',
        'flood-warning-count__icon',
        'flood-warning-count__count',
        'flood-warning-count__dropdown-icon',
        'flood-warning-count__right',
        'flood-warning-count-out-of-force-desktop__count',
        'flood-warning-count-out-of-force-desktop__dropdown-icon',
        'flood-warning-count-out-of-force-mobile__text',
        'flood-warning-count-out-of-force-mobile__dropdown-icon',
        'flood-warning-banner__out-of-force',
        'flood-warning-count-out-of-force-desktop'
    ],

    init: function () {
        this.severeDropDownToggle.addEventListener("click", this.toggleDropDown.bind(this, this.severeDropDownToggle, this.severeDropDown, ""));
        this.warningDropDownToggle.addEventListener("click", this.toggleDropDown.bind(this, this.warningDropDownToggle, this.warningDropDown, ""));
        this.alertDropDownToggle.addEventListener("click", this.toggleDropDown.bind(this, this.alertDropDownToggle, this.alertDropDown, ""));
        this.outOfForceDropDownToggle.addEventListener("click", this.toggleDropDown.bind(this, this.outOfForceDropDownToggle, this.outOfForceDropDown, this.outOfForceWarningButtonOpenClassName));
        this.outOfForceMobileDropDownToggle.addEventListener("click", this.toggleDropDown.bind(this, this.outOfForceMobileDropDownToggle, this.outOfForceMobileDropDown, this.outOfForceMobileWarningButtonOpenClassName));
    },

    toggleDropDown: function (target, dropDown, buttonClassOverride) {

        const activeDropDownButtonClass = buttonClassOverride ? buttonClassOverride : this.buttonOpenClassName;
        const activeDropDownContainerClass = this.dropdownOpenClassname;

        let makeThisActive = !target.classList.contains(activeDropDownButtonClass);

        this.closeAllDropDowns();

        if (!makeThisActive) return;

        target.classList.add(activeDropDownButtonClass);
        dropDown.classList.add(activeDropDownContainerClass);

        this.registerEventListeners();
    },

    registerEventListeners: function () {

        if (this.eventListenersRegistered) {
            return;
        }

        document.addEventListener("keydown", (e) => {
                var isEscape = false;
                if ("key" in e) {
                    isEscape = (e.key === "Escape" || e.key === "Esc");
                } else {
                    isEscape = (e.keyCode === 27);
                }
                if (isEscape && this.isAnyDropDownOpen()) {
                    this.closeAllDropDowns();
                }
            });

        document.addEventListener('click', (e) => {

            const target = e.target || e.srcElement;
            if (this.isBannerItem(target)) {
                return;
            }

            if (!this.isAnyDropDownOpen()) {
                return;
            }

            this.closeAllDropDowns();
        });

        this.eventListenersRegistered = true;
    },

    isBannerItem: function(element) {
        return this.bannerItemClassList.some(e => element.classList.contains(e));
    },

    isAnyDropDownOpen: function () {
        return this.severeDropDown.classList.contains(this.dropdownOpenClassname)
            || this.warningDropDown.classList.contains(this.dropdownOpenClassname)
            || this.alertDropDown.classList.contains(this.dropdownOpenClassname)
            || this.outOfForceDropDown.classList.contains(this.dropdownOpenClassname)
            || this.outOfForceMobileDropDown.classList.contains(this.dropdownOpenClassname);
    },

    closeAllDropDowns: function () {
        document.querySelectorAll("a.flood-warning-count").forEach((a) => {
            a.classList.remove(this.buttonOpenClassName)
        });

        document.querySelectorAll(".flood-warning-count-dropdown").forEach((a) => {
            a.classList.remove(this.dropdownOpenClassname);
        });
        
        this.outOfForceDropDownToggle.classList.remove(this.outOfForceWarningButtonOpenClassName);
        this.outOfForceMobileDropDownToggle.classList.remove(this.outOfForceMobileWarningButtonOpenClassName);
    },

    calculateSummaries(list) {
        const severeList = list.filter(x => x.severity === 1);
        const warningList = list.filter(x => x.severity === 2);
        const alertList = list.filter(x => x.severity === 3);
        
        this.toggleOutOfForceWarningCounts(list);

        const severe = severeList.length;
        const warnings = warningList.length;
        const alerts = alertList.length;

        this.updateAriaLabel(this.severeDropDownToggle, this.severeBannerCount.innerHTML, severe);
        this.updateAriaLabel(this.warningDropDownToggle, this.warningBannerCount.innerHTML, warnings);
        this.updateAriaLabel(this.alertDropDownToggle, this.alertBannerCount.innerHTML, alerts);

        this.severeBannerCount.innerHTML = severe;
        this.warningBannerCount.innerHTML = warnings;
        this.alertBannerCount.innerHTML = alerts;

        this.populateListItems(this.severeDropDownItemsContainer, severeList);
        this.populateListItems(this.warningDropDownItemsContainer, warningList);
        this.populateListItems(this.alertDropDownItemsContainer, alertList);        
    },

    updateAriaLabel: function(element, originalValue, newValue) {
        // get the current aira-label
        let currentLabel = element.getAttribute('aria-label');

        if (currentLabel) {
            currentLabel = currentLabel.replace(originalValue, newValue);
        }
        element.setAttribute('aria-label', currentLabel);
    },

    toggleOutOfForceWarningCounts: function (list) {
        const activeOutOfForceWarningClassname = "flood-warning-count-out-of-force-desktop__active";
        const mobileOutOfForceWarningContainerClass = "flood-warning-count-out-of-force-mobile";

        const outOfForceList = list.filter(x => x.severity === 4);

        let outOfForceLabel = Resource.FloodWarningBannerRemovedWarnings;

        outOfForceLabel = outOfForceLabel.replace("{0}", outOfForceList.length);
        document.querySelector(".flood-warning-count-out-of-force-desktop__count").innerHTML = outOfForceLabel;
        document.querySelector(`.${mobileOutOfForceWarningContainerClass}__text`).innerHTML = outOfForceLabel.replace(outOfForceList.length, '');
        document.querySelector(`.${mobileOutOfForceWarningContainerClass}__count`).innerHTML = outOfForceList.length;
        if (outOfForceList.length === 0) {
            this.outOfForceDropDownToggle.classList.remove(activeOutOfForceWarningClassname);
            this.outOfForceDropDownToggle.classList.remove(this.outOfForceWarningButtonOpenClassName);
            this.outOfForceDropDown.classList.remove(this.dropdownOpenClassname);
            document.querySelector(`.${mobileOutOfForceWarningContainerClass}`).classList.add("display-none");
        } else {
            this.outOfForceDropDownToggle.classList.add(activeOutOfForceWarningClassname);
            document.querySelector(`.${mobileOutOfForceWarningContainerClass}`).classList.remove("display-none")
        }

        this.populateListItems(this.outOfForceDropDownItemsContainer, outOfForceList);
        this.populateListItems(this.outOfForceMobileDropDownItemsContainer, outOfForceList);
    },

    populateListItems(listContainer, list) {
        let html = '';
        list.forEach(liveMessage => {
            html += templates.getDropDownListItemTemplate(liveMessage);
        });

        listContainer.innerHTML = html;
    }
}

export default summary;
