﻿import languageUtility from "../Util/language";
import constants from "./mapConstants";
import moment from "moment";
import { Resource } from "../Util/resource";

const templates = {
    getMapPopupTemplate: function (model) {
        return `<div class="live-message-popup">
                    <div class="live-message-popup__severity-name">${model.severityName}</div>
                    <a href="${model.uri}" class="live-message-popup__name">${languageUtility.text(model.name)}</a>
                    <div class="live-message-popup__date">
                        ${Resource.Issued}: <span class="live-message-popup__issued">${moment(model.issued).format(constants.moment.dateFormat)}</span>
                    </div>
                    <div class="live-message-popup__date">
                        ${Resource.Updated}: <span class="live-message-popup__updated">${moment(model.updated).format(constants.moment.dateFormat)}</span>
                    </div>
                    <a class="button button--primary live-message-popup__view-details-button" href="${model.uri}" role="link">${Resource.ViewDetails}</a>
                </div>`;
    },

    getListItemTemplate: function (model, cssClasses, icon) {
        if (model.severity === 4) {
            icon = "";
        }

        var issued = this.getLiveMessageDateTime(model.issued);
        var updated = this.getLiveMessageDateTime(model.updated);

        return `
             <div class="live-message live-message-list__item ${cssClasses}" data-fwacode="${model.fwaCode}">
                <div class="live-message__severity">
                    ${icon}
                    <span class="live-message__severity-name">${model.severityName}</span>
                </div>
                <div class="live-message__details">
                    <div class="live-message__top">
                        <a class="live-message__name" href="${model.uri}" role="link">${languageUtility.text(model.name)}</a>
                    </div>
                    <div class="live-message__bottom">
                        <div class="live-message__dates">
                            <span class="live-message__date">${Resource.Issued}: <wbr /><span class="live-message__issued">${issued}</span></span>
                            <span class="live-message__date">${Resource.Updated}: <wbr /><span class="live-message__updated">${updated}</span></span>
                        </div>
                        ${this.getDescription(model.description)}
                        <a class="button button--primary live-message__view-details-button" href="${model.uri}" role="link">${Resource.ViewDetails}</a>
                    </div>
                </div>
            </div>`;
    },

    getLiveMessageDateTime(datetime) {
        var formattedDateTime = 'n/a';

        if (datetime) {
            formattedDateTime = `${moment(datetime).format(constants.moment.dateFormat)}`;
        }

        return formattedDateTime;
    },

    getDescription(description) {
        if (description) {
            return `
                <div class="live-message__description">
                    <span>${languageUtility.text(description)}</span>
                </div>`
        } else {
            return ''
        }
    },

    getMapIconTemplate: function () {
        return `<img class="easy-button-icon" src="/images/actionicons/home-outline.svg" alt="${Resource.MapHome}" title="${Resource.MapHome}" />`;
    },

    getClusterIconTemplate: function (clusterIconUrl, count, selectedCssClass) {
        return `<div style="background-image: url('${clusterIconUrl}');" class="cluster-icon-container ${selectedCssClass}">
                    <div class="cluster-icon-text">${count}</div>
                </div>`;
    },

    getDropDownListItemTemplate: function (liveMessage) {
        return `<li class="flood-warning-count-dropdown__target-area-list-item" role="listitem" data-fwacode="${liveMessage.fwaCode}">
                    <a class="flood-warning-count-dropdown__target-area-list-item-link" href="${liveMessage.uri}" role="link">${languageUtility.text(liveMessage.name)}</a>
                </li>`;
    },

    getSearchResultsListGroupTemplate: function (resultsGroup) {
        const displayTotals = resultsGroup.showTotalMessage ? '' : 'display-none';

        const searchResultsGroupContainer = document.createElement('div');
        searchResultsGroupContainer.classList.add('search-results__type');
        
        const searchResultsTitleDiv = document.createElement('div');
        searchResultsTitleDiv.classList.add('search-results__title');
        searchResultsTitleDiv.innerText = resultsGroup.title;

        const statsDiv = document.createElement('div');
        statsDiv.classList.add('list-stats');
        statsDiv.classList.add('search-results__type-stats');
        if (displayTotals) statsDiv.classList.add(displayTotals);

        if (resultsGroup.showTotalMessage) {
            statsDiv.innerText = resultsGroup.showTotalMessage;
        }
        
        searchResultsTitleDiv.appendChild(statsDiv);

        searchResultsGroupContainer.appendChild(searchResultsTitleDiv);

        const listDiv = document.createElement('div');
        listDiv.classList.add('search-results__results');
        listDiv.setAttribute('role', 'group');
        listDiv.setAttribute('id', resultsGroup.id);

        searchResultsGroupContainer.appendChild(listDiv);

        return {
            searchResultsGroupContainer: searchResultsGroupContainer,
            listDiv: listDiv
        };
    }
};

export default templates;
