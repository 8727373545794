import telemetry from '../../telemetry/application-insights';

const rivers = {

    _resource: null,

    doSearch: function (keyword, resource) {
        this._resource = resource;

        const uri = `api/search/rivers?query=${keyword}`;

        return new Promise((resolve, reject) => {
            $.get(uri, data => {
                resolve(this._parseResults(data));
            }).fail(() => {
                let errorMessage = 'Failed to get rivers';
                if (telemetry.logError) telemetry.logError(null, errorMessage)
                reject(errorMessage);
            });
        });
    },

    _parseResults: function (data) {
        let results = [];

        for (const property in data.results) {
            const points = this.getPoints(data.results[property].rings);
            results.push({
                text: {
                    english: property,
                    welsh: property
                },
                points: points,
                selected: this.listItemSelected.bind(points)
            });
        }

        let showTotalMessage = '';
        if (data.totalResults > 4 && data.matchedResults < data.totalResults) {
            showTotalMessage = this._resource.SearchResultsCategoryListStats.replace('{start}', data.matchedResults).replace('{total}', data.totalResults);
        }

        return {
            id: 'rivers',
            title: this._resource.SearchCategoryRivers,
            results: results,
            showTotalMessage: showTotalMessage
        };
    },

    getPoints: function(rings) {
        // crude, but works for rivers - unpacks the points so that it's just a straight array of points
        if (rings.length === 1) {
            return this.getPoints(rings[0]);
        }

        return rings;
    },

    listItemSelected: function (map, callback) {
        if (callback) callback();

        // convert to polygon so we can easily get the bounding rectangle
        let polygon = map.convertToPolygon(this);

        map.centreOnPoint({ extent: polygon.getBounds() });
    }
};

export default rivers;
