﻿const keyCodes = {
    tab: 9,
    control: 17,
    escape: 27,
    spacebar: 32,
    arrowLeft: 37,
    arrowUp: 38,
    arrowRight: 39,
    arrowDown: 40,
    meta: 91,
    return: 13,
    alt: 18,
    shift: 16
}

const keys = {
    isArrowKey: function(keyCode) {
        if (keyCode === keyCodes.arrowUp
            || keyCode === keyCodes.arrowDown
            || keyCode === keyCodes.arrowLeft
            || keyCode === keyCodes.arrowRight) {
            return true;
        }

        return false;
    }
}

export { keyCodes, keys };
