﻿const dataStorage = (function() {
    var _baseMapUrl = null;
    var _liveMessages = null;
    var _allMessages = null;
    var _fwaaCodes = null;

    function getBaseMapUrl() {
        return _baseMapUrl;
    }

    function setBaseMapUrl(value) {
        _baseMapUrl = value;
    }

    function getLastUpdated() {
        var liveMessages = _liveMessages;
        return {
            lastMessage: liveMessages.lastLiveMessage,
            lastRefresh: liveMessages.lastRefresh
        };
    }

    function getLiveMessages() {
        var liveMessages = _liveMessages;
        return liveMessages.messages;
    }

    function getAllMessages() {
        return _allMessages.messages;
    }

    function setLiveMessages(value) {
        _liveMessages = value;
    }

    function setAllMessages(value) {
        _allMessages = value;
    }

    function setLastRefreshDateTime(value) {
        var liveMessages = _liveMessages;
        liveMessages.lastRefresh = value;
        this.setLiveMessages(liveMessages);
    }

    function getFwaaCodes() {
        return _fwaaCodes;
    }

    function setFwaaCodes(value) {
        _fwaaCodes = value;
    }

    return {
        getBaseMapUrl: getBaseMapUrl,
        setBaseMapUrl: setBaseMapUrl,
        getLastUpdated: getLastUpdated,
        getLiveMessages: getLiveMessages,
        getAllMessages: getAllMessages,
        setLiveMessages: setLiveMessages,
        setAllMessages: setAllMessages,
        setLastRefreshDateTime: setLastRefreshDateTime,
        getFwaaCodes: getFwaaCodes,
        setFwaaCodes: setFwaaCodes
    }
})();

export default dataStorage;
