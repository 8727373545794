const httpResponseCodes = {
    success: 200,
    badRequest: 400,
    internalServerError: 500
}

const reverseLookupResult = {
    none: 0,
    withinWales: 1,
    outsideWales: 2,
    unknown: 3
}

export default { httpResponseCodes, reverseLookupResult };